import AppComponents from "../components";
import EventValue from "../eventvalue";
import Localization from "../localize";
import Poster, { ownerSetToString, ownerStringToSet, PopupPosterSetting, PosterSyncState } from "../poster";
import SVGLoader from "../svgloader";
import PopupHelper from "./helper";
import PopupPart, { PopupButton } from "./part";

export default class PopupPoster extends PopupPart {

    private static readonly buttonSetNames = {
        add: "add",
        modify: "modify",
    };

    private values: { [setting: string]: EventValue<string> };

    private valueElements: { [state: number]: HTMLElement };

    constructor() {
        super("poster");

        this.values = {};
        this.valueElements = {};

        this.values[PopupPosterSetting.State] = new EventValue<string>();
        this.values[PopupPosterSetting.Position] = new EventValue<string>();
        this.values[PopupPosterSetting.Owner] = new EventValue<string>();
        this.values[PopupPosterSetting.Comment] = new EventValue<string>();
        this.values[PopupPosterSetting.SelectionSize] = new EventValue<string>();

        this.generateHTML();
        this.registerEvents();
    }

    public generateHTML() {
        this.generateStateHTML();
        this.generatePositionHTML();
        this.generateOwnerHTML();
        this.generateCommentHTML();
        this.registerLabelEvents();
        this.addButtons();
    }

    private generateStateHTML() {
        const values: string[] = [];

        for (const state in Localization.displayStrings.state) {
            if (Localization.displayStrings.state.hasOwnProperty(state) && state === String(+state)) {
                values.push(state);
            }
        }

        const valueToString = (state) => {
            return Localization.displayStrings.state[state][0];
        };

        const valueToSvg = (state: string, operation: (svg) => void) => {
            return PopupHelper.GenerateStateSvg(+state, operation);
        };

        const stateParent = this.CreateDropDown(this.values[PopupPosterSetting.State], values, valueToString, valueToSvg);

        this.AddTitleRow(Localization.displayStrings.popupPoster.state, PopupPosterSetting.State, stateParent);
    }

    private generatePositionHTML() {
        const values: string[] = [];
        for (const position in Localization.displayStrings.position) {
            if (Localization.displayStrings.position.hasOwnProperty(position)) {
                values.push(position);
            }
        }
        const valueToString = (position) => {
            return Localization.displayStrings.position[position];
        };

        const valueToSvg = (position: string, operation: (svg) => void) => {
            return SVGLoader.positionLoader.Execute((svg) => {
                SVGLoader.IsolateChild(svg, `position${position}-icon`);
                operation(svg);
            });
        };

        const positionElement = this.CreateDropDown(this.values[PopupPosterSetting.Position], values, valueToString, valueToSvg);

        this.AddTitleRow(Localization.displayStrings.popupPoster.position, PopupPosterSetting.Position, positionElement);
    }

    private CreateDropDown(eventValue: EventValue<string>, values: string[], valueToString?: (v) => string, valueToSvg?: (v: string, o: (s: SVGElement) => void) => void): HTMLElement {
        const parentElement = document.createElement("div");
        parentElement.classList.add("dropdown");

        const currentValueParent = document.createElement("div");

        const currentValue = document.createElement("span");

        const dropdownContentElement = document.createElement("div");
        dropdownContentElement.classList.add("dropdown-content");

        let dropdownVisible = false;
        const showHideDropdown = (show?: boolean) => {
            if (show === undefined) {
                show = !dropdownVisible;
            }

            if (show !== dropdownVisible) {
                dropdownContentElement.style.display = show ? "block" : "none";
                this.activateTransparentForeground(show);

                if (show) {
                    this.transparentForegroundElement.onclick = () => {
                        showHideDropdown(false);
                    };
                }
                dropdownVisible = show;
            }
        };
        showHideDropdown(false);

        currentValueParent.onclick = () => {
            showHideDropdown();
        };

        parentElement.appendChild(currentValueParent);

        SVGLoader.materialIconsLoader.Execute((svg) => {
            SVGLoader.IsolateChild(svg, "arrow-drop-down");
            svg.classList.add("dropdownarrow");
            currentValueParent.appendChild(currentValue);
            currentValueParent.appendChild(svg);
        });

        values.forEach((value) => {
            const _value = value;

            const option = document.createElement("div") as HTMLDivElement;

            option.onclick = () => {
                eventValue.value = _value;
            };

            const displayString = valueToString ? valueToString(_value) : _value;

            if (valueToSvg) {
                valueToSvg(_value, (svg) => {
                    svg.classList.add("inline-icon");
                    option.appendChild(svg);
                    option.appendChild(document.createTextNode(displayString));
                });
            } else {
                option.innerText = displayString;
            }
            dropdownContentElement.appendChild(option);
        });

        parentElement.appendChild(dropdownContentElement);

        eventValue.OnChanged.on(
            () => {
                const displayString = valueToString ? valueToString(eventValue.value) : eventValue.value;

                if (valueToSvg) {
                    valueToSvg(eventValue.value, (svg) => {
                        svg.classList.add("inline-icon");

                        currentValue.innerText = displayString;
                        currentValue.appendChild(svg);
                    });
                } else {
                    currentValue.innerText = displayString;
                }
                showHideDropdown(false);
            }
        );

        return parentElement;
    }

    private generateOwnerHTML() {
        const eventValue = this.values[PopupPosterSetting.Owner];
        const ownerParent = document.createElement("div");

        const ownerElement = document.createElement("textarea") as HTMLTextAreaElement;
        ownerElement.required = true;
        ownerElement.placeholder = Localization.displayStrings.popupPoster.ownerplaceholder;

        ownerParent.appendChild(ownerElement);
        ownerParent.appendChild(document.createElement("br"));

        ownerElement.oninput = () => {
            eventValue.value = ownerElement.value;
        };
        this.values[PopupPosterSetting.Owner].OnReset.on((() => {
            ownerElement.value = eventValue.value;
        }));
        this.AddTitleRow(Localization.displayStrings.popupPoster.owner, PopupPosterSetting.Owner, ownerParent);
        this.valueElements[PopupPosterSetting.Owner] = ownerElement;
    }

    private generateCommentHTML() {
        const eventValue = this.values[PopupPosterSetting.Comment];

        const commentElement = document.createElement("textarea");
        commentElement.placeholder = Localization.displayStrings.popupPoster.commentplaceholder;
        commentElement.oninput = () => {
            eventValue.value = commentElement.value;
        };
        this.values[PopupPosterSetting.Comment].OnReset.on((() => {
            commentElement.value = eventValue.value;
        }));
        this.AddTitleRow(Localization.displayStrings.popupPoster.comment, PopupPosterSetting.Comment, commentElement, true);
        this.valueElements[PopupPosterSetting.Comment] = commentElement;
    }

    private addButtons() {
        const _this = this;

        const modifyButtons = [];
        const addButtons = [];

        const saveFunction = () => {
            let drawnFeature = true;

            AppComponents.ApplicationState.selectedPosterFeatures.forEach((feature) => {
                const poster = feature.get(Poster.FeaturePosterProperty) as Poster;

                let anythingChanged = false;
                for (const value in _this.values) {
                    if (_this.values.hasOwnProperty(value)) {
                        const setting = value as PopupPosterSetting;
                        if (_this.values[value].IsDirty) {
                            anythingChanged = true;
                            poster.PopupSet(setting, _this.values[value].value);
                        }
                    }
                }

                if (anythingChanged) {
                    feature.set(Poster.FeaturePosterProperty, poster);

                    AppComponents.ApplicationState.setSyncToAddOrModify(feature, PosterSyncState.Modify);

                    if (feature.get(Poster.FeatureSyncProperty) !== PosterSyncState.Add) {
                        drawnFeature = false;
                    }
                }
            });

            if (drawnFeature) {
                AppComponents.ApplicationState.defaultPosterState = Number(_this.values[PopupPosterSetting.State].value);
                AppComponents.ApplicationState.defaultPosterPosition = Number(_this.values[PopupPosterSetting.Position].value);
                AppComponents.ApplicationState.defaultOwners = ownerStringToSet(_this.values[PopupPosterSetting.Owner].value);
            }

            AppComponents.ApplicationState.selectedFeatures.clear();
        };

        const saveButtonClasses = ["popup-button-default"];
        const saveButton = new PopupButton(saveFunction, Localization.displayStrings.popupPoster.save, "content-save_white", saveButtonClasses);
        modifyButtons.push(saveButton);
        addButtons.push(saveButton);

        const clearSelected = () => {
            AppComponents.ApplicationState.selectedFeatures.clear();
        };

        const abortButtonClasses = ["popup-button-normal"];
        const abortButton = new PopupButton(clearSelected, Localization.displayStrings.popupPoster.abort, "close", abortButtonClasses);
        modifyButtons.push(abortButton);

        const deleteFunction = () => {
            AppComponents.ApplicationState.selectedPosterFeatures.forEach((feature) => {
                AppComponents.ApplicationState.setSyncToAddOrModify(feature, PosterSyncState.Delete);
            });

            AppComponents.ApplicationState.selectedFeatures.clear();
            return false;
        };

        const deleteButtonClasses = ["popup-button-warning"];
        const deleteButton = new PopupButton(deleteFunction, Localization.displayStrings.popupPoster.delete, "delete_red", deleteButtonClasses);
        modifyButtons.push(deleteButton);
        addButtons.push(deleteButton);

        const sizeSelectionElement = document.createElement("span");

        this.values[PopupPosterSetting.SelectionSize].OnChanged.on(() => {
            const size = _this.values[PopupPosterSetting.SelectionSize].value;
            sizeSelectionElement.style.display = +size === 1 ? "none" : "inline-block";
            sizeSelectionElement.innerText = size;
        });

        this.valueElements[PopupPosterSetting.SelectionSize] = sizeSelectionElement;

        this.AddButtons(PopupPoster.buttonSetNames.modify, modifyButtons, sizeSelectionElement);
        this.AddButtons(PopupPoster.buttonSetNames.add, addButtons);
    }

    private registerLabelEvents() {
        for (const value in this.values) {
            if (this.values.hasOwnProperty(value) && this.labelElements.hasOwnProperty(value)) {
                this.values[value].OnDirty.on(() => {
                    this.labelElements[value].style.textDecoration = "underline";
                });
                this.values[value].OnReset.on(() => {
                    this.labelElements[value].style.textDecoration = "unset";
                });
            }
        }
    }

    private registerEvents() {
        const _this = this;

        AppComponents.Events.Selection.Active.on(() => _this.setPosterHTMLValues());
    }

    private setPosterHTMLValues() {
        let state: number;
        let position: number;
        let comment: string;
        const owners = new Set<string>();

        let allOwnersIdentical = true;

        let firstFeature = true;
        let isAddMode = false;
        AppComponents.ApplicationState.selectedPosterFeatures.forEach((feature) => {
            const poster = feature.get(Poster.FeaturePosterProperty) as Poster;

            if (feature.get(Poster.FeatureSyncProperty) === PosterSyncState.Add) {
                isAddMode = true;
            }

            if (firstFeature || (state && state === poster.State)) {
                state = poster.State;
            } else {
                state = undefined;
            }

            position = poster.Position;
            poster.Owners.forEach((owner) => {
                if (owner !== "") {
                    owners.add(owner);
                }
            });

            if (allOwnersIdentical) {
                owners.forEach((owner) => {
                    if (!poster.Owners.has(owner)) {
                        allOwnersIdentical = false;
                    }
                });
            }

            comment = poster.Comment;
            firstFeature = false;
        });
        this.values[PopupPosterSetting.State].Reset(state ? String(state) : String(AppComponents.ApplicationState.defaultPosterState));
        this.values[PopupPosterSetting.Position].Reset(String(position));

        this.values[PopupPosterSetting.Owner].Reset(ownerSetToString(owners, true));
        this.values[PopupPosterSetting.Comment].Reset(comment);
        this.values[PopupPosterSetting.SelectionSize].Reset(String(AppComponents.ApplicationState.selectedPosterFeatures.length));

        this.valueElements[PopupPosterSetting.Owner].disabled = !allOwnersIdentical;

        this.ActivateButtonSet(isAddMode ? PopupPoster.buttonSetNames.add : PopupPoster.buttonSetNames.modify);
    }
}
