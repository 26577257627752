import AppComponents from "./components";
import Localization from "./localize";

export default class Backend {

    private backendUrl: string;

    private optimizeSession = false;

    constructor() {
        this.backendUrl = process.env.BACKEND;
        if (this.backendUrl === undefined) {
            this.backendUrl = require("/backend/backend.php");
        }
    }

    public sendRequest(request: Request) {
        AppComponents.Overlays.ShowSpinner();

        const xhr = new XMLHttpRequest();
        xhr.open("POST", this.backendUrl, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=utf-8");

        let params = `operation=${request.operation}`;

        if (!this.optimizeSession && AppComponents.User.UsernameAndPasswordSet) {
            params += `&auth_user=${encodeURIComponent(AppComponents.User.Username)}&auth_pw=${encodeURIComponent(AppComponents.User.Password)}`;
        }

        for (const key in request.params) {
            if (request.params.hasOwnProperty(key)) {
                params += `&${key}=${encodeURIComponent(request.params[key])}`;
            }
        }
        params = params.replace(/%20/g, "+");

        for (const key in request.header) {
            if (request.header.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, request.header[key]);
            }
        }

        if (!request.errorFunction) {
            request.errorFunction = () => {
                window.setTimeout(() => {
                    AppComponents.Backend.sendRequest(request);
                }, 2000);
            };
        }

        xhr.onload = () => {
            try {
                if (xhr.readyState === 4) {
                    this.optimizeSession = xhr.getResponseHeader("X-Optimize-Session") === "true";
                    if (xhr.status === 401 && request.autoLoginLogout) {
                        AppComponents.User.Logout(true);
                    } else if (!request.responseFunctions[xhr.status]) {
                        if (xhr.status !== 200 && xhr.status !== 304) {
                            let message;
                            if (Localization.displayStrings.httpstatus[xhr.status]) {
                                message = Localization.displayStrings.httpstatus[xhr.status];
                            } else {
                                message = Localization.displayStrings.httpstatus.default + xhr.status;
                            }

                            AppComponents.Overlays.DisplayWarning(message, xhr.responseText);
                            request.errorFunction();
                        }
                    } else {
                        request.responseFunctions[xhr.status](xhr);
                    }

                    AppComponents.Overlays.HideSpinner();
                }
            } catch (err) {
                request.errorFunction();
            }
        };

        xhr.onerror = () => {
            request.errorFunction();
        };

        xhr.send(params);
    }
}

export class Request {
    public operation: string;
    public params: {
        [param: string]: string
    };
    public header: {
        [header: string]: string
    };
    public responseFunctions: {
        [responseCode: number]: (xhr: XMLHttpRequest) => void
    };
    public autoLoginLogout = true;
    public errorFunction: () => void;

    constructor() {
        this.params = {};
        this.responseFunctions = {};
        this.header = {};
    }
}
