import Control from "ol/control/Control";
import AppComponents from "../components";
import CSSConstants from "../cssconstants";
import HTMLLoader from "../htmlloader";
import Localization from "../localize";
import SVGLoader from "../svgloader";
import { IsDemoMode } from "../demomode";

export default class Menu {
    private readonly menuElement = document.getElementById("menu");
    private readonly menuBackgroundElement = document.getElementById("menu-background");
    private readonly menuContentTopElement = document.getElementById("menu-content-top");
    private readonly menuContentBottomElement = document.getElementById("menu-content-bottom");

    private adminModeSpan: HTMLSpanElement;

    constructor() {
        this.addMenuEntries();
        this.registerEvents();
    }

    public show(): void {
        this.menuElement.classList.remove(CSSConstants.hidden);
    }

    public hide(): void {
        this.menuElement.classList.add(CSSConstants.hidden);
    }

    private addMenuEntries(): void {
        this.addMenuEntry(Localization.displayStrings.menu.map, () => this.hide(), MenuPosition.Top, "map");
        if (document.body.requestFullscreen && document.exitFullscreen) {
            this.addMenuEntry(Localization.displayStrings.menu.fullscreen, () => {
                if (document.fullscreen) {
                    document.exitFullscreen();
                } else {
                    document.body.requestFullscreen();
                }
            }, MenuPosition.Top, "fullscreen");
        }
        this.addMenuEntry(Localization.displayStrings.menu.help, () => {
            AppComponents.Subpage.showStatic(Localization.displayStrings.menu.help, AppComponents.Help.generateHelpPage());
        }, MenuPosition.Top, "book-open-variant");
        this.adminModeSpan = this.addMenuEntry(Localization.displayStrings.menu.administation, () => {
            AppComponents.Admin.displayAdminpage();
        }, MenuPosition.Top, "settings");
        if (!IsDemoMode()) {
            this.addMenuEntry(Localization.displayStrings.menu.logout, () => AppComponents.User.StartLogout(), MenuPosition.Top, "logout");
        }
        this.addMenuEntry(Localization.displayStrings.menu.about, () => {
            AppComponents.Subpage.showStatic(Localization.displayStrings.menu.about, HTMLLoader.contentLoader.getChild("about"));
        }, MenuPosition.Bottom, "heart");
        this.addMenuEntry(Localization.displayStrings.menu.privacy, () => {
            AppComponents.Subpage.showStatic(Localization.displayStrings.menu.privacy, HTMLLoader.contentLoader.getChild("privacy"));
        }, MenuPosition.Bottom);
        this.addMenuEntry(Localization.displayStrings.menu.legal, () => {
            AppComponents.Subpage.showStatic(Localization.displayStrings.menu.legal, HTMLLoader.contentLoader.getChild("legal"));
        }, MenuPosition.Bottom);

        AppComponents.Map.map.addControl(new Control({ element: this.menuElement }));
    }

    private addMenuEntry(text: string, operation: () => void, position: MenuPosition, iconId?: string): HTMLSpanElement {
        const _this = this;
        const spanElement = document.createElement("span");

        if (iconId) {
            SVGLoader.materialIconsLoader.Execute((svg) => {
                SVGLoader.IsolateChild(svg, iconId);
                spanElement.appendChild(svg);
                spanElement.appendChild(document.createTextNode(text));
            });
        } else {
            spanElement.innerText = text;
        }
        spanElement.onclick = () => {
            _this.hide();
            operation();
        };

        const contentElement = position === MenuPosition.Top ? this.menuContentTopElement : this.menuContentBottomElement;
        contentElement.appendChild(spanElement);

        return spanElement;
    }

    private registerEvents(): void {
        const _this = this;
        this.menuBackgroundElement.onclick = () => {
            _this.hide();
        };
        AppComponents.Events.User.Login.on(() => {
            if (AppComponents.Admin.IsAdmin) {
                this.adminModeSpan.classList.remove(CSSConstants.hidden);
            } else {
                this.adminModeSpan.classList.add(CSSConstants.hidden);
            }
        });
    }
}

enum MenuPosition {
    Top,
    Bottom
}
