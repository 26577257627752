import LiteEvent from "./liteevent";

export default class Events {
    public Owners = new Owners();
    public Selection = new Selection();
    public AddMode = new AddMode();
    public Filter = new Filter();
    public User = new User();
}

export class Owners {
    private onChange = new LiteEvent<void>();
    public get Change() { return this.onChange.expose(); }
}

export class Selection {
    private none = new LiteEvent<void>();
    private active = new LiteEvent<void>();

    public get None() { return this.none.expose(); }
    public get Active() { return this.active.expose(); }
}

export class AddMode {
    private onAddModeStart = new LiteEvent<void>();
    private onAddModeEnd = new LiteEvent<void>();

    public get Start() { return this.onAddModeStart.expose(); }
    public get End() { return this.onAddModeEnd.expose(); }
}

export class Filter {
    private onFilterOn = new LiteEvent<void>();
    private onFilterOff = new LiteEvent<void>();
    private onFilterChanged = new LiteEvent<void>();
    private onFilterConfigurationStart = new LiteEvent<void>();
    private onFilterConfigurationEnd = new LiteEvent<void>();

    public get On() { return this.onFilterOn.expose(); }
    public get Off() { return this.onFilterOff.expose(); }
    public get Changed() { return this.onFilterChanged.expose(); }
    public get ConfigurationStart() { return this.onFilterConfigurationStart.expose(); }
    public get ConfigurationEnd() { return this.onFilterConfigurationEnd.expose(); }
}

export class User {
    private readonly onLogin = new LiteEvent<void>();
    private readonly onLogout = new LiteEvent<void>();
    public get Login() { return this.onLogin.expose(); }
    public get Logout() { return this.onLogout.expose(); }
}
