import { fromLonLat } from "ol/proj.js";
import { Request } from "./backend";
import AppComponents from "./components";
import CSSConstants from "./cssconstants";
import Localization from "./localize";
import { ownerStringToSet } from "./poster";
import { GetUrlParam, UrlParams } from "./url";
export default class User {

    public get Username(): string {
        return this.username;
    }

    public set Username(value: string) {
        this.username = value;
        User.loginElements.username.value = this.username;
    }

    public get Password(): string {
        return this.password;
    }

    public set Password(value: string) {
        this.password = value;
        User.loginElements.password.value = value;
    }

    public get UsernameAndPasswordSet(): boolean {
        return this.username && this.password && this.username !== "" && this.password !== "";
    }

    private get IsLoginScreenHidden(): boolean {
        return User.loginElements.screen.classList.contains(CSSConstants.hidden);
    }

    private static readonly loginElements = {
        screen: document.getElementById("loginscreen"),
        form: document.getElementById("logincredentials"),
        username: document.getElementById("username") as HTMLInputElement,
        password: document.getElementById("password") as HTMLInputElement,
        submit: document.getElementById("login"),
        message: document.getElementById("login-message"),
    };

    public messageTimeout: number;
    public EMail: string;
    public UserPasswordSet = false;

    private username: string;
    private password: string;

    constructor() {
        this.addHTMLElements();

        const usernameFromUrl = GetUrlParam(UrlParams.user);
        if (usernameFromUrl) {
            this.Username = usernameFromUrl;
        }

        this.registerEvents();
    }

    public setMessageTimeout() {
        if (this.messageTimeout) {
            window.clearTimeout(this.messageTimeout);
        }

        const _this = this;
        this.messageTimeout = window.setTimeout(() => {
            _this.HideMessage();
        }, 5000);
    }

    public addHTMLElements() {
        // Login form
        User.loginElements.username.setAttribute("placeholder", Localization.displayStrings.login.username);
        User.loginElements.password.setAttribute("placeholder", Localization.displayStrings.login.password);
        User.loginElements.submit.innerHTML = Localization.displayStrings.login.submit;

        const _this = this;
        User.loginElements.form.onsubmit = () => {
            if (User.loginElements.username.value !== "" && User.loginElements.password.value !== "") {
                _this.Username = User.loginElements.username.value;
                _this.Password = User.loginElements.password.value;
                _this.Authenticate();
            }
        };
    }

    public Authenticate(): void {
        const request = new Request();
        request.operation = "authenticate";

        request.responseFunctions[200] = (xhr) => {
            const xml = xhr.responseXML;

            const usernameElements = xml.getElementsByTagName("user");
            if (usernameElements.length === 1) {
                this.Username = usernameElements[0].textContent;
            }

            const emailElements = xml.getElementsByTagName("email");
            if (emailElements.length === 1) {
                this.EMail = emailElements[0].textContent;
            }

            const defaultOwnerElements = xml.getElementsByTagName("defaultowner");
            if (defaultOwnerElements.length === 1 && defaultOwnerElements[0].firstChild != null) {
                AppComponents.ApplicationState.defaultOwners = ownerStringToSet(defaultOwnerElements[0].textContent);
            }

            const centerElements = xml.getElementsByTagName("center");
            if (centerElements.length === 1) {
                const centerArray = centerElements[0].textContent.split(",");
                const lat = +centerArray[0];
                const lon = +centerArray[1];
                const center = fromLonLat([lon, lat]);
                AppComponents.Map.centerViewTo(center);
            }

            const userPasswordSetElement = xml.getElementsByTagName("userpasswordset");
            if (userPasswordSetElement.length === 1) {
                const userPasswordSet = userPasswordSetElement[0].textContent;
                AppComponents.User.UserPasswordSet = (userPasswordSet === "true");
            }

            const adminElements = xml.getElementsByTagName("admin");
            if (adminElements.length === 1) {
                const admin = adminElements[0].textContent;
                AppComponents.Admin.IsAdmin = (admin === "true");
            }

            const superuserElements = xml.getElementsByTagName("superuser");
            if (superuserElements.length === 1) {
                const superuser = superuserElements[0].textContent;
                AppComponents.Admin.IsSuperuser = superuser === "true";
            }

            AppComponents.Events.User.Login.trigger();

            AppComponents.PosterSync.synchronize(false);
        };

        AppComponents.Backend.sendRequest(request);
    }

    public StartLogout(): void {
        const request = new Request();
        request.operation = "logout";
        request.autoLoginLogout = false;

        AppComponents.Backend.sendRequest(request);
        this.Logout(false);
    }

    public Logout(showError: boolean) {
        if (this.IsLoginScreenHidden) {
            this.ShowLoginScreen();
        } else {
            if (showError && this.UsernameAndPasswordSet) {
                this.ShowMessage(Localization.displayStrings.login.messageLoginFailed);
            } else {
                this.HideMessage();
            }
        }

        AppComponents.Events.User.Logout.trigger();
    }

    public HideLoginScreen() {
        User.loginElements.screen.classList.add(CSSConstants.hidden);
    }

    private registerEvents(): void {
        const _this = this;
        AppComponents.Events.User.Login.on(() => {
            _this.HideLoginScreen();
        });
    }

    private ShowLoginScreen() {
        User.loginElements.screen.classList.remove(CSSConstants.hidden);
    }

    private ShowMessage(message: string) {
        User.loginElements.message.innerText = message;
        User.loginElements.message.classList.remove(CSSConstants.hidden);
        this.setMessageTimeout();
    }

    private HideMessage() {
        User.loginElements.message.classList.add(CSSConstants.hidden);
    }
}

export { User };

