import "./css/main.scss";
import "./css/ol.css";
import AppComponents from "./js/components";
import DemoMode, { IsDemoMode } from "./js/demomode";

export default class PosterManagement {

  public start() {
    AppComponents.Popup.addParts();
    AppComponents.Controls.addControls();
    AppComponents.Subpage;
    AppComponents.Menu.hide();
    AppComponents.Localization;
    AppComponents.Help;

    if (IsDemoMode()) {
      DemoMode.PrepareDemoMode();
    } else {
      AppComponents.User.Authenticate();
    }
  }
}

require("dotenv").config();
const p = new PosterManagement();
p.start();
