import Control from "ol/control/Control";
import AppComponents from "../components";
import CSSConstants from "../cssconstants";
import SVGLoader from "../svgloader";

export default class Subpage {
    private readonly subpageElement = document.getElementById("subpage");
    private readonly headerElement = document.getElementById("subpage-header");
    private titleElement: HTMLElement;
    private contentElement = document.getElementById("subpage-content");
    private backgroundElement = document.getElementById("subpage-background");

    private readonly subpageStack: Array<() => [string, HTMLElement]> = [];

    constructor() {
        this.createElements();
        this.registerEvents();
    }

    public showStatic(title: string, content: HTMLElement | string): void {
        const contentElement = document.createElement("div");
        if (typeof (content) === "string") {
            contentElement.innerHTML = content;
        } else {
            contentElement.appendChild(content);
        }
        this.showFlexible(() => [title, contentElement]);
    }

    public showFlexible(createFunction: () => [string, HTMLElement]): void {
        this.removeChildNodes();

        this.subpageStack.push(createFunction);

        this.showContent(createFunction);

        this.subpageElement.classList.remove(CSSConstants.hidden);
    }

    public hide(removeEverything?: boolean): void {
        if (removeEverything) {
            while (this.subpageStack.length !== 0) {
                this.subpageStack.pop();
            }

            this.removeChildNodes();
        }
        this.subpageElement.classList.add(CSSConstants.hidden);
    }

    public back(): void {
        this.removeChildNodes();

        this.subpageStack.pop();

        if (this.subpageStack.length === 0) {
            this.hide(true);
            return;
        }

        const topElement = this.subpageStack[this.subpageStack.length - 1];
        this.showContent(topElement);
    }

    private showContent(createFunction: () => [string, HTMLElement]): void {
        const content = createFunction();

        this.titleElement.innerText = content[0];
        this.contentElement.appendChild(content[1]);
    }

    private removeChildNodes(): void {
        while (this.contentElement.firstChild) {
            this.contentElement.removeChild(this.contentElement.firstChild);
        }
    }

    private registerEvents(): void {
        const _this = this;
        AppComponents.Events.User.Logout.on(() => {
            _this.hide(true);
        });
    }

    private createElements(): void {
        const _this = this;
        this.titleElement = document.createElement("span");
        SVGLoader.materialIconsLoader.Execute((svg: SVGElement) => {
            const arrowElement = SVGLoader.IsolateChild(svg, "arrow-left");
            arrowElement.style.fill = "#fff";

            svg.onclick = () => {
                _this.back();
            };
            svg.id = "subpage-back";
            this.headerElement.appendChild(svg);
            this.headerElement.appendChild(this.titleElement);
        });

        this.backgroundElement.onclick = () => {
            _this.hide(true);
        };

        AppComponents.Map.map.addControl(new Control({ element: this.subpageElement }));
    }
}
