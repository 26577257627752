export default class SVGLoader {

    public get CloneSvg(): SVGElement {
        if (this.svg) {
            return this.svg.cloneNode(true) as SVGElement;
        } else {
            return null;
        }
    }
    public static readonly clusterLoader = new SVGLoader(require("~/icons/cluster.svg"));
    public static readonly materialIconsLoader = new SVGLoader(require("~/icons/material.svg"));
    public static readonly stateLoader = new SVGLoader(require("~/icons/state.svg"));
    public static readonly positionLoader = new SVGLoader(require("~/icons/position.svg"));

    public static IsolateChild(svgContentDocument: SVGElement, id: string): SVGElement {
        let resultElement: SVGElement;

        svgContentDocument.childNodes.forEach((child) => {
            if (child.nodeType === child.ELEMENT_NODE) {
                const childElement = child as HTMLElement;

                if (childElement.hasAttribute("id")) {
                    if (childElement.getAttribute("id") === id) {
                        resultElement = child as SVGElement;
                        childElement.removeAttribute("id");
                    } else {
                        svgContentDocument.removeChild(child);
                    }
                }
            }
        });

        return resultElement;
    }

    private readonly svgUrl: string;

    private svg: SVGElement;

    private pendingOperations: Array<(svg: SVGElement) => void> = [];

    constructor(svgUrl: string) {
        this.svgUrl = svgUrl;

        this.loadSvg();
    }

    public Execute(operation: (svg) => void) {
        if (this.svg) {
            operation(this.CloneSvg);
        } else {
            this.pendingOperations.push(operation);
        }
    }

    private loadSvg() {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", this.svgUrl);
        xhr.overrideMimeType("image/svg+xml");

        xhr.onload = () => {
            if (xhr.readyState === 4) {
                const documentElement = xhr.responseXML.documentElement as unknown;
                this.svg = documentElement as SVGElement;

                while (this.pendingOperations.length !== 0) {
                    const operation = this.pendingOperations.pop();
                    operation(this.CloneSvg);
                }
            }
        };

        xhr.send("");
    }
}
