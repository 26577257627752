import { toLonLat } from "ol/proj.js";
import AppComponents from "../components";
import Localization from "../localize";
import PopupPart, { PopupButton } from "./part";

export default class PopupMapCenter extends PopupPart {

    private centerCoordinateElement: HTMLSpanElement;

    constructor() {
        super("mapcenter");

        this.generateHTML();
        this.registerEvents();
    }

    private generateHTML(): void {
        const mapCenterElement = document.createElement("div");

        const mapCenterDescriptionElement = document.createElement("div");
        mapCenterDescriptionElement.innerText = Localization.displayStrings.popupMapCenter.centerdescription;
        mapCenterElement.appendChild(mapCenterDescriptionElement);

        const mapCenterValueParent = document.createElement("div");
        mapCenterValueParent.innerText = Localization.displayStrings.popupMapCenter.centervalue;

        this.centerCoordinateElement = document.createElement("span");
        mapCenterValueParent.appendChild(this.centerCoordinateElement);
        mapCenterElement.appendChild(mapCenterValueParent);

        this.AddTitleRow(Localization.displayStrings.popupMapCenter.title, "center", mapCenterElement, true);

        this.addButtons();
    }

    private registerEvents(): void {
        const view = AppComponents.Map.view;
        view.on("change:center", () => {
            const center = AppComponents.Map.view.getCenter() as ol.Coordinate;
            const lonLat = toLonLat(center);
            this.centerCoordinateElement.innerText = `${Math.round(lonLat[1] * 100) / 100}°, ${Math.round(lonLat[0] * 100) / 100}°`;
        });
    }

    private addButtons() {
        const buttons = [];

        const closeFunction = () => {
            AppComponents.Admin.mapCenterEnd();
        };

        const saveFunction = () => {
            AppComponents.Admin.setMapCenter(AppComponents.Map.map.getView().getCenter());
            closeFunction();
        };

        const saveButtonClasses = ["popup-button-default"];
        const saveButton = new PopupButton(saveFunction, Localization.displayStrings.popupMapCenter.save, "content-save_white", saveButtonClasses);
        buttons.push(saveButton);

        const closeButtonClasses = ["popup-button-normal"];
        const abortButton = new PopupButton(closeFunction, Localization.displayStrings.popupMapCenter.abort, "close", closeButtonClasses);
        buttons.push(abortButton);

        this.AddButtons("buttons", buttons);
    }
}
