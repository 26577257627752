import { default as LiteEvent } from "./liteevent";

export default class EventValue<T> {
    private _value: T;
    private dirty: boolean;

    private readonly valueChangedEvent = new LiteEvent<void>();
    private readonly valueDirtyEvent = new LiteEvent<void>();
    private readonly valueResetEvent = new LiteEvent<void>();

    constructor(value?: T) {
        this._value = value;
        this.dirty = false;
    }

    public get value() {
        return this._value;
    }

    public set value(value: T) {
        this._value = value;
        this.dirty = true;
        this.valueChangedEvent.trigger();
        this.valueDirtyEvent.trigger();
    }

    public Reset(value: T) {
        this._value = value;
        this.valueChangedEvent.trigger();
        this.valueResetEvent.trigger();
    }

    public get OnChanged() {
        return this.valueChangedEvent.expose();
    }

    public get OnDirty() {
        return this.valueDirtyEvent.expose();
    }

    public get OnReset() {
        return this.valueResetEvent.expose();
    }

    public get IsDirty() {
        return this.dirty;
    }
}
