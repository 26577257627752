import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat, toLonLat } from "ol/proj.js";
import { Request } from "./backend";
import AppComponents from "./components";
import { IsDemoMode } from "./demomode";
import Localization from "./localize";
import Poster, { ownerSetToString, PosterSyncState } from "./poster";

export default class PosterSync {
    public posterEtag = "";
    public posterLastModified = "";
    public synchronizeTimeout: number;

    constructor() {
        this.registerEvents();
    }

    public setSynchronizeTimeout(duration?: number) {
        const _this = this;

        let retryAfter = 30000;
        if (duration) {
            retryAfter = duration;
        }

        this.synchronizeTimeout = window.setTimeout(() => {
            _this.synchronize(false);
        }, retryAfter);
    }

    public clearSynchronizeTimeout() {
        if (this.synchronizeTimeout) {
            window.clearTimeout(this.synchronizeTimeout);
        }
    }

    public synchronize(submitOnly: boolean) {
        if (IsDemoMode()) {
            AppComponents.ApplicationState.posterSubmitQueue.forEach((posterFeature) => {
                AppComponents.ApplicationState.posterSubmitQueue.remove(posterFeature);

                operation = posterFeature.get(Poster.FeatureSyncProperty) as PosterSyncState;

                switch (operation) {
                    case PosterSyncState.Add:
                    case PosterSyncState.Modify:

                        AppComponents.ApplicationState.posterFeatures.push(posterFeature);
                        posterFeature.set(Poster.FeatureSyncProperty, PosterSyncState.Synced);
                }
            });

            return;
        }

        const _this = this;
        if (submitOnly && AppComponents.ApplicationState.posterSubmitQueue.getLength() === 0) {
            return;
        }

        this.clearSynchronizeTimeout();

        if (AppComponents.ApplicationState.selectedFeatures.getLength() > 0) {
            this.setSynchronizeTimeout();
            return;
        }

        const request = new Request();

        request.responseFunctions[304] = () => {
            _this.setSynchronizeTimeout();
        };

        request.errorFunction = () => {
            AppComponents.Overlays.DisplayWarning(Localization.displayStrings.backendError);
            _this.setSynchronizeTimeout(5000);
        };

        let operation;
        if (AppComponents.ApplicationState.posterSubmitQueue.getLength() > 0) {
            const posterFeature = AppComponents.ApplicationState.posterSubmitQueue.item(0);

            operation = posterFeature.get(Poster.FeatureSyncProperty) as PosterSyncState;

            switch (operation) {
                case PosterSyncState.Add:
                case PosterSyncState.Modify:
                    request.operation = (operation === PosterSyncState.Add ? "addposter" : "modifyposter");

                    const poster = posterFeature.get(Poster.FeaturePosterProperty) as Poster;

                    const lonlat = toLonLat(posterFeature.getGeometry().getCoordinates());
                    request.params.coordinates = lonlat[1] + "," + lonlat[0];
                    request.params.state = String(poster.State);
                    request.params.position = String(poster.Position);
                    request.params.owner = ownerSetToString(poster.Owners, false);
                    request.params.comment = poster.Comment;

                    if (operation === PosterSyncState.Modify) {
                        request.params.id = posterFeature.getId();
                    }

                    request.responseFunctions[200] = () => {
                        AppComponents.ApplicationState.posterSubmitQueue.remove(posterFeature);
                        AppComponents.Map.posterVectorSource.addFeature(posterFeature);

                        _this.synchronize(false);
                    };

                    break;
                case PosterSyncState.Delete:
                    request.operation = "deleteposter";

                    request.params.id = posterFeature.getId();

                    request.responseFunctions[200] = () => {
                        AppComponents.ApplicationState.posterSubmitQueue.remove(posterFeature);
                        // alert(req.responseText);
                        _this.synchronize(false);
                    };

                    break;
                default:
                    break;
            }
        } else {
            request.operation = "listposter";

            if (this.posterLastModified !== "") {
                request.header["If-Modified-Since"] = this.posterLastModified;
                if (this.posterEtag !== "") {
                    request.header["If-None-Match"] = this.posterEtag;
                }
            }

            request.responseFunctions[200] = (req) => {
                if (req) {
                    const etag = req.getResponseHeader("ETag");
                    const lastModified = req.getResponseHeader("Last-Modified");
                    if (etag === "" || _this.posterEtag !== etag || _this.posterLastModified !== lastModified) {
                        _this.posterEtag = etag;
                        _this.posterLastModified = lastModified;
                        _this.updatePoster(req.responseXML);
                    }
                }
                _this.setSynchronizeTimeout();
            };
        }

        AppComponents.Backend.sendRequest(request);
    }

    private registerEvents() {
        const _this = this;

        AppComponents.Events.Selection.None.on(() => {
            if (AppComponents.ApplicationState.posterSubmitQueue.getLength() > 0) {
                _this.synchronize(true);
            }
        });

        AppComponents.Events.User.Logout.on(() => {
            _this.posterEtag = "";
            _this.posterLastModified = "";
            _this.clearSynchronizeTimeout();
        });
    }

    private updatePoster(xml: Document) {
        try {
            if (xml) {
                const featureArray = [];

                const posterElements = Array.from(xml.getElementsByTagName("poster"));

                posterElements.forEach((posterElement) => {
                    const poster = new Poster();

                    const id = posterElement.getElementsByTagName("id")[0].textContent;
                    const coordinatesArray = posterElement.getElementsByTagName("coordinates")[0].textContent.split(",");
                    const lat = +coordinatesArray[0];
                    const lon = +coordinatesArray[1];
                    const coordinates = fromLonLat([lon, lat]);

                    poster.State = +posterElement.getElementsByTagName("state")[0].textContent;
                    poster.Position = +posterElement.getElementsByTagName("position")[0].textContent;

                    let commaSeparatedOwners = "";
                    const ownerElements = posterElement.getElementsByTagName("owner");
                    if (ownerElements.length > 0 && ownerElements[0].textContent !== "") {
                        commaSeparatedOwners = ownerElements[0].textContent;
                    }

                    commaSeparatedOwners.split(",").forEach((owner) => {
                        poster.Owners.add(owner);
                    });

                    const commentElements = posterElement.getElementsByTagName("comment");
                    if (commentElements.length > 0 && commentElements[0].firstChild != null) {
                        poster.Comment = commentElements[0].firstChild.textContent;
                    }
                    const posterFeature = new Feature();

                    posterFeature.setGeometry(new Point(coordinates));
                    posterFeature.setId(id);

                    posterFeature.set(Poster.FeaturePosterProperty, poster);
                    posterFeature.set(Poster.FeatureSyncProperty, PosterSyncState.Synced);

                    featureArray.push(posterFeature);
                });

                AppComponents.ApplicationState.posterFeatures.clear();
                AppComponents.Map.posterVectorSource.addFeatures(featureArray);
            }
        } catch (err) {
            AppComponents.Overlays.DisplayWarning(Localization.displayStrings.posterXMLError + " " + err);
        }
    }

}
