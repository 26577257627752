import Collection from "ol/Collection";
import Control from "ol/control/Control";
import { MapBrowserEvent } from "ol/events.js";
import { platformModifierKeyOnly } from "ol/events/condition.js";
import Feature from "ol/Feature";
import Geolocation from "ol/Geolocation";
import Point from "ol/geom/Point";
import { TOUCH as browserHasTouch } from "ol/has";
import DragBox from "ol/interaction/DragBox";
import Draw from "ol/interaction/Draw";
import Select from "ol/interaction/Select";
import Translate from "ol/interaction/Translate";
import TileLayer from "ol/layer/Tile";
import Vector from "ol/layer/Vector";
import Map from "ol/Map";
import Cluster from "ol/source/Cluster";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import View from "ol/View";
import AppComponents from "./components";
import CSSConstants from "./cssconstants";
import Localization from "./localize";
import Poster, { PosterSyncState } from "./poster";
import { getPosterFeatures } from "./state";
import SVGLoader from "./svgloader";

export default class MapComponent {

    public readonly map: Map = new Map({
        controls: new Collection<Control>(),
        layers: this.mapLayers(),
        target: "map"
    });

    public draw: Draw;
    public translate: Translate;

    public accuracyFeature: Feature;
    public positionFeature: Feature;

    public posterLayer: Vector;
    public clusterLayer: Vector;
    public posterSubmitLayer: Vector;
    public posterVectorSource: VectorSource;

    public expandedClusters = new Set<Feature>();

    public visible = false;

    // Minimum accuracy for the geolocation until the geolocation stops
    public readonly requiredAccuracy = 20000;
    public readonly goodAccuracy = 15;

    public readonly view = new View({
        center: Localization.MapCenter,
        zoom: 13,
        projection: "EPSG:3857"
    });

    public get geolocation(): Geolocation {
        return this._geolocation;
    }

    private _geolocation = new Geolocation({
        projection: this.view.getProjection(),
    });

    private clusteredFeatures = new Set<Feature>();

    private posterStyleCache: Style[] = [];
    private clusterStyleCache: Style[] = [];

    constructor() {
        this.addPositionLayer();
        this.addPosterLayer();
        this.addPosterSubmitLayer();
        this.addInteractions();
        this.setGeolocationOptions();

        this.createStyleCache();

        this.registerEvents();
    }
    public addPositionLayer() {
        // Display the user's position on the map

        this.accuracyFeature = new Feature();
        this.positionFeature = new Feature();
        this.positionFeature.setStyle(new Style({
            image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                    color: "#3399CC",
                }),
                stroke: new Stroke({
                    color: "#fff",
                    width: 2,
                }),
            }),
        }));

        const positionLayer = new Vector({
            source: new VectorSource({
                features: [this.accuracyFeature, this.positionFeature],
            }),
        });
        this.map.addLayer(positionLayer);
    }
    public setPositionGeometry(coordinates) {
        this.positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        this.centerViewTo(coordinates, true);
    }
    public setPositionAccuracy(accuracyGeometry) {
        this.accuracyFeature.setGeometry(accuracyGeometry);
    }

    public addPosterLayer() {
        const _this = this;

        this.posterVectorSource = new VectorSource({
            features: AppComponents.ApplicationState.posterFeatures,
        });

        this.posterLayer = new Vector({
            source: this.posterVectorSource,
            style(feature) {
                return _this.posterStyleFunction(feature);
            },
        });

        const posterClusterSource = new Cluster({
            distance: browserHasTouch ? 36 : 24,
            source: this.posterVectorSource
        });

        this.clusterLayer = new Vector({
            source: posterClusterSource,
            style(feature) {
                return _this.clusterStyleFunction(feature);
            },
        });

        this.map.addLayer(this.clusterLayer);
        this.map.addLayer(this.posterLayer);

        AppComponents.Events.Filter.Changed.on(() => {
            this.clusterLayer.changed();
            this.posterLayer.changed();
        });
    }

    public addPosterSubmitLayer() {
        const _this = this;

        const posterSubmitVectorSource = new VectorSource({
            features: AppComponents.ApplicationState.posterSubmitQueue,
        });

        this.posterSubmitLayer = new Vector({
            source: posterSubmitVectorSource,
            style(feature) {
                return _this.posterStyleFunction(feature);
            },
            opacity: 0.5,
        });
        this.map.addLayer(this.posterSubmitLayer);
    }

    public stateStyleFunction(state: string): Style {
        if (!state) {
            state = "0";
        }

        const style = this.posterStyleCache[state];
        if (!style) {
            return undefined;
        }

        return style;
    }

    public centerViewTo(coordinates: [number, number], increaseZoom?: boolean): void {
        let zoom = this.view.getZoom();
        if (increaseZoom && zoom < 17) {
            zoom = 17;
        }
        this.view.animate({
            duration: 500,
            center: coordinates,
            zoom,
        });
    }

    public setGeolocationOptions() {
        const _this = this;

        this.geolocation.setTrackingOptions({
            enableHighAccuracy: true,
            maximumAge: 10000,
            timeout: 60000,
        });

        this.geolocation.setTracking(false);

        this.geolocation.on("change:accuracyGeometry", () => {
            const accuracy = _this.geolocation.getAccuracy();
            if (accuracy <= _this.requiredAccuracy) {
                _this.setPositionAccuracy(_this.geolocation.getAccuracyGeometry());
            }
        });

        this.geolocation.on("change:position", () => {
            const coordinates = _this.geolocation.getPosition();
            const accuracy = _this.geolocation.getAccuracy();
            if (coordinates && accuracy <= _this.requiredAccuracy) {
                _this.setPositionGeometry(coordinates);
                if (accuracy <= _this.goodAccuracy) {
                    _this.geolocation.setTracking(false);
                }
            }
        });
    }

    private addInteractions() {
        const _this = this;

        const select = new Select({
            features: AppComponents.ApplicationState.selectedFeatures,
            hitTolerance: 10,
            style() {
                return _this.stateStyleFunction("s");
            },
            layers: [this.posterLayer, this.posterSubmitLayer],
        });
        this.map.addInteraction(select);

        AppComponents.Events.Selection.Active.on(() => _this.clusterLayer.changed());
        AppComponents.Events.Selection.None.on(() => _this.clusterLayer.changed());

        const dragBox = new DragBox({
            condition: platformModifierKeyOnly,
        });
        this.map.addInteraction(dragBox);

        dragBox.on("boxend", () => {
            // features that intersect the box are added to the collection of
            // selected features
            const extent = dragBox.getGeometry().getExtent();
            _this.posterVectorSource.forEachFeatureIntersectingExtent(extent, (feature) => {
                if (AppComponents.Filters.posterDisplayedFunction(feature)) {
                    AppComponents.ApplicationState.selectedFeatures.push(feature);
                }
            });
            this.posterLayer.changed();
        });

        // clear selection when drawing a new box and when clicking on the map
        dragBox.on("boxstart", () => {
            AppComponents.ApplicationState.selectedFeatures.clear();
        });

        this.translate = new Translate({
            features: AppComponents.ApplicationState.selectedFeatures,
            layers: [this.posterLayer, this.posterSubmitLayer]
        });
        this.map.addInteraction(this.translate);

        this.translate.on("translateend", (evt: Translate.Event) => {
            evt.features.forEach((clusterFeature) => {
                const posterFeatures = getPosterFeatures(clusterFeature);
                posterFeatures.forEach((posterFeature) => {
                    posterFeature.setGeometry(clusterFeature.getGeometry());
                    AppComponents.ApplicationState.setSyncToAddOrModify(posterFeature, PosterSyncState.Modify);
                });
            });
        });

        this.draw = new Draw({
            features: AppComponents.ApplicationState.posterSubmitQueue,
            type: "Point",
            style() {
                return _this.stateStyleFunction(String(AppComponents.ApplicationState.defaultPosterState));
            }
        });

        this.draw.on("drawend", function (evt: Draw.Event) {
            const newFeature = evt.feature;

            const poster = new Poster();
            poster.State = AppComponents.ApplicationState.defaultPosterState;
            poster.Position = AppComponents.ApplicationState.defaultPosterPosition;
            poster.Owners = AppComponents.ApplicationState.defaultOwners;
            poster.Comment = "";

            newFeature.set(Poster.FeaturePosterProperty, poster);
            newFeature.set(Poster.FeatureSyncProperty, PosterSyncState.Add);

            AppComponents.ApplicationState.DisableAddMode();
        });

        AppComponents.Events.AddMode.Start.on(() => this.map.addInteraction(this.draw));
        AppComponents.Events.AddMode.End.on(() => this.map.removeInteraction(this.draw));

        this.map.on("click", (mapBrowserEvent: MapBrowserEvent) => {
            let singleFeature: Feature | ol.render.Feature;
            _this.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (feature, layer) => {
                if (layer === _this.clusterLayer) {
                    if (!singleFeature) {
                        singleFeature = feature;
                    } else {
                        return false;
                    }
                }
            });
            if (singleFeature) {
                const point = singleFeature.getGeometry() as Point;
                _this.view.animate({
                    center: point.getCoordinates(),
                    zoom: _this.view.getZoom() + 2
                });
            }
            return false;
        });
    }

    private clusterStyleFunction(feature): Style {
        const posterFeaturesUnfiltered = getPosterFeatures(feature);
        const posterFeatures = [];
        let style: Style;

        let size = 0;

        const selectedFeatures = new Set<Feature>(AppComponents.ApplicationState.selectedPosterFeatures);

        posterFeaturesUnfiltered.forEach((posterFeature) => {
            if (!selectedFeatures.has(posterFeature) && AppComponents.Filters.posterDisplayedFunction(posterFeature)) {
                size++;
                posterFeatures.push(posterFeature);
            }
        });

        if (size <= 1) {
            posterFeatures.forEach((posterFeature) => {
                this.clusteredFeatures.delete(posterFeature);
            });
            return undefined;
        }

        if (size > 1) {
            const statesOfPosters = new Set<number>();

            posterFeatures.forEach((posterFeature) => {
                this.clusteredFeatures.add(posterFeature);
                const poster = posterFeature.get(Poster.FeaturePosterProperty) as Poster;
                const state = poster.State;
                statesOfPosters.add(state);
            });
            const sizeString = size <= 99 ? size.toString() : "+";

            const statesOfPostersArray = Array.from(statesOfPosters);
            statesOfPostersArray.sort();

            const styleId = `${statesOfPostersArray.join()}-${sizeString}`;

            style = this.clusterStyleCache[styleId];

            if (!style) {

                const svg = SVGLoader.clusterLoader.CloneSvg;

                if (svg != null) {
                    const state0 = String(statesOfPostersArray[0]);
                    const state1 = statesOfPostersArray.length >= 2 ? String(statesOfPostersArray[1]) : state0;
                    const state2 = statesOfPostersArray.length >= 3 ? String(statesOfPostersArray[2]) : state0;

                    svg.childNodes.forEach((child) => {
                        if (child.nodeType === child.ELEMENT_NODE) {
                            const childElement = child as HTMLElement;

                            if (childElement.hasAttribute("id")) {
                                const childId = childElement.getAttribute("id");
                                childElement.removeAttribute("id");

                                if (childId === "locationpin0") {
                                    childElement.style.fill = Localization.displayStrings.state[state0][1];
                                }
                                if (childId === "locationpin1") {
                                    childElement.style.fill = Localization.displayStrings.state[state1][1];
                                }
                                if (childId === "locationpin2") {
                                    if (size === 2) {
                                        childElement.parentElement.removeChild(childElement);
                                    } else {
                                        childElement.style.fill = Localization.displayStrings.state[state2][1];
                                    }
                                }
                            }
                        }
                    });

                    const serialized = escape(new XMLSerializer().serializeToString(svg));

                    style = new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            anchorXUnits: "fraction",
                            anchorYUnits: "fraction",
                            scale: browserHasTouch ? 1.5 : 1,
                            src: `data:image/svg+xml;utf8,${serialized}`,
                        }),
                        text: new Text({
                            text: sizeString,
                            scale: browserHasTouch ? 1.8 : 1.2,
                            fill: new Fill({ color: "#fff" }),
                            offsetY: browserHasTouch ? -33 : -22
                        })
                    });
                    this.clusterStyleCache[styleId] = style;
                }
            }
        }
        return style;
    }

    private posterStyleFunction(feature: Feature | ol.render.Feature): Style {
        if (!AppComponents.Filters.posterDisplayedFunction(feature)) {
            return undefined;
        }
        if (this.clusteredFeatures.has(feature)) {
            return undefined;
        }

        const poster = feature.get(Poster.FeaturePosterProperty) as Poster;

        return this.stateStyleFunction(String(poster.State));
    }

    private createStyleCache() {
        const _this = this;

        for (const state in Localization.displayStrings.state) {
            if (Localization.displayStrings.state.hasOwnProperty(state)) {
                SVGLoader.stateLoader.Execute((svg) => {
                    svg.childNodes.forEach((child) => {
                        if (child.nodeType === child.ELEMENT_NODE) {
                            const childElement = child as HTMLElement;

                            if (childElement.hasAttribute("id")) {
                                const childId = childElement.getAttribute("id");
                                if (childId === "locationpin") {
                                    childElement.style.fill = Localization.displayStrings.state[state][1];
                                } else if (childId !== `state-${state}-icon`) {
                                    svg.removeChild(childElement);
                                } else {
                                    childElement.removeAttribute("id");
                                }
                            }
                        }
                    });

                    if (state === "h") {
                        const centerElement = document.getElementById("center");
                        centerElement.appendChild(svg);
                    }

                    const serialized = escape(new XMLSerializer().serializeToString(svg));

                    const style = new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            anchorXUnits: "fraction",
                            anchorYUnits: "fraction",
                            scale: browserHasTouch ? 1.5 : 1,
                            src: `data:image/svg+xml;utf8,${serialized}`,
                        })
                    });
                    _this.posterStyleCache[state] = style;
                });
            }
        }
    }

    private registerEvents(): void {
        const _this = this;

        AppComponents.Events.Filter.Changed.on(() => _this.posterLayer.changed());
        AppComponents.Events.User.Login.on(() => {
            this.visible = true;
            _this.map.setView(this.view);

            document.getElementById("map").classList.remove(CSSConstants.hidden);
            document.getElementById("background").classList.add(CSSConstants.hidden);
        });
        AppComponents.Events.User.Logout.on(() => {
            this.visible = false;

            document.getElementById("map").classList.add(CSSConstants.hidden);
            document.getElementById("background").classList.remove(CSSConstants.hidden);
        });
    }

    private mapLayers() {
        const layers = [];

        layers.push(new TileLayer({
            visible: true,
            source: new OSM(),
        }));

        return layers;
    }
}
