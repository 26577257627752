import AppComponents from "./components";
import HTMLLoader from "./htmlloader";
import Localization from "./localize";
import ListPage from "./ui/listpage";

export default class Help {
    constructor() {
        this.registerEvents();
    }

    public generateHelpPage(): HTMLElement {
        if (!AppComponents.Admin.IsAdmin) {
            return HTMLLoader.contentLoader.getChild("help-user");
        }
        const helpEntryPage = new ListPage();
        helpEntryPage.add(Localization.displayStrings.help.user.title,
            () => AppComponents.Subpage.showStatic(Localization.displayStrings.help.user.title, HTMLLoader.contentLoader.getChild("help-user")),
            Localization.displayStrings.help.user.description, "map-marker-add");
        helpEntryPage.add(Localization.displayStrings.help.admin.title,
            () => AppComponents.Subpage.showStatic(Localization.displayStrings.help.admin.title, HTMLLoader.contentLoader.getChild("help-admin")),
            Localization.displayStrings.help.admin.description, "settings");
        if (AppComponents.Admin.IsSuperuser) {
            helpEntryPage.add(Localization.displayStrings.help.superuser.title,
                () => AppComponents.Subpage.showStatic(Localization.displayStrings.help.superuser.title, HTMLLoader.contentLoader.getChild("help-superuser")),
                Localization.displayStrings.help.superuser.description, "account-supervisor");
        }

        return helpEntryPage.Content;
    }

    private registerEvents(): void {
        const _this = this;
        AppComponents.Events.User.Login.on(() => {
            if (AppComponents.Admin.IsAdmin && !AppComponents.User.UserPasswordSet) {
                AppComponents.Subpage.showStatic(Localization.displayStrings.menu.help, _this.generateHelpPage());
            }
        });
    }
}
