import CSSConstants from "../cssconstants";
import SVGLoader from "../svgloader";

export default class ListPage {
    private readonly listParentElement = document.createElement("div");

    constructor() {
        this.listParentElement.classList.add(CSSConstants.list.list);
    }

    public add(title: string, operation?: () => void, description?: string | HTMLDivElement, iconId?: string): HTMLDivElement {
        const listElement = document.createElement("div");
        listElement.classList.add(CSSConstants.list.element);

        if (operation) {
            listElement.onclick = () => {
                operation();
            };
        } else {
            listElement.classList.add(CSSConstants.disabled);
        }

        const addElements = () => {
            const titleElement = document.createElement("div");
            titleElement.innerText = title;
            titleElement.classList.add(CSSConstants.list.title);

            listElement.appendChild(titleElement);

            if (description) {
                let descriptionElement: HTMLDivElement;
                if (typeof (description) === "string") {
                    descriptionElement = document.createElement("div");
                    descriptionElement.innerText = description;
                } else {
                    descriptionElement = description;
                }

                descriptionElement.classList.add(CSSConstants.list.description);
                listElement.appendChild(descriptionElement);
            }
        };

        if (iconId) {
            SVGLoader.materialIconsLoader.Execute((svg) => {
                SVGLoader.IsolateChild(svg, iconId);
                svg.classList.add(CSSConstants.list.icon);
                listElement.appendChild(svg);
                addElements();
            });
        } else {
            addElements();
        }

        this.listParentElement.appendChild(listElement);
        return listElement;
    }

    public get Content(): HTMLDivElement {
        return this.listParentElement;
    }
}
