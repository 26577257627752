import Point from "ol/geom/Point";
import AppComponents from "../components";
import PopupFilter from "./filter";
import PopupPoster from "./poster";
import CSSConstants from "../cssconstants";
import PopupMapCenter from "./mapcenter";

export default class Popup {

    private readonly popupElement = document.getElementById("popup");

    private readonly popupPartElements = [];

    constructor() {
        this.registerEvents();
    }

    public addParts(): void {
        const posterPart = new PopupPoster();
        this.popupPartElements[EPopupContent.Poster] = posterPart.PartDiv;

        const filterPart = new PopupFilter();
        this.popupPartElements[EPopupContent.Filter] = filterPart.PartDiv;

        const mapCenterPart = new PopupMapCenter();
        this.popupPartElements[EPopupContent.MapCenter] = mapCenterPart.PartDiv;

        for (const part in this.popupPartElements) {
            if (this.popupPartElements.hasOwnProperty(part)) {
                this.popupElement.appendChild(this.popupPartElements[part]);
            }
        }
    }

    public showPopup(show: boolean, style?: EPopupContent) {
        if (!show) {
            this.popupElement.classList.add(CSSConstants.hidden);
            return;
        } else {
            this.popupElement.classList.remove(CSSConstants.hidden);
        }

        for (const part in this.popupPartElements) {
            if (this.popupPartElements.hasOwnProperty(part)) {
                this.showOrHidePopupPart(this.popupPartElements[part], part === style);
            }
        }
    }

    private registerEvents() {
        const _this = this;

        AppComponents.Events.Selection.Active.on(() => {
            _this.showPopup(true, EPopupContent.Poster);

            // Move the selected feature into the center of the visible map area above the popup
            if (AppComponents.ApplicationState.selectedFeatures.getLength() === 1) {
                window.setTimeout(() => {
                    const mapSize = AppComponents.Map.map.getSize();
                    const popupHeight = _this.popupElement.offsetHeight;

                    // How much the center has to be moved up relatively
                    const centerFactor = 0.5 - ((mapSize[1] - popupHeight) / mapSize[1] / 2);

                    const viewExtent = AppComponents.Map.view.calculateExtent(mapSize);
                    const viewHeight = viewExtent[3] - viewExtent[1];

                    // How much the center has to be moved up absolutely
                    const moveUpCoordinates = viewHeight * centerFactor;

                    const featureGeometry = AppComponents.ApplicationState.selectedFeatures.item(0).getGeometry() as Point;
                    const featureCoordinates = featureGeometry.getCoordinates();

                    if (AppComponents.Map.view.getRotation() === 0) {
                        AppComponents.Map.centerViewTo([featureCoordinates[0], featureCoordinates[1] - moveUpCoordinates], false);
                    } else {
                        AppComponents.Map.view.centerOn(featureCoordinates, mapSize, [mapSize[0] / 2, (mapSize[1] - popupHeight) / 2]);
                    }
                }, 100);
            }
        }
        );
        AppComponents.Events.Selection.None.on(() => {
            _this.showPopup(false);
        }
        );

        AppComponents.Events.Filter.ConfigurationStart.on(() => {
            _this.showPopup(true, EPopupContent.Filter);
        });

        AppComponents.Events.Filter.ConfigurationEnd.on(() => {
            _this.showPopup(false);
        });

        AppComponents.Map.translate.on("translatestart", () => {
            _this.showPopup(false);
        });

        AppComponents.Map.translate.on("translateend", () => {
            _this.showPopup(true, EPopupContent.Poster);
        });
    }

    private showOrHidePopupPart(part: HTMLElement, show: boolean) {
        part.style.display = show ? "block" : "none";
    }
}

export enum EPopupContent {
    Poster = "Poster",
    Filter = "Filter",
    MapCenter = "MapCenter",
}
