import AppComponents from "../components";
import Localization from "../localize";
import PopupHelper from "./helper";
import PopupPart, { PopupButton } from "./part";

export default class PopupFilter extends PopupPart {

    private ownerParent: HTMLDivElement;

    private updateFilterState: () => void;
    private updateFilterOwner: () => void;

    constructor() {
        super("filter");

        this.generateHTML();

        this.registerEvents();
    }

    private registerEvents() {
        const _this = this;

        AppComponents.Events.Owners.Change.on(() => {
            _this.buildOwnerFilterHTML();
        });

        AppComponents.Events.Filter.Changed.on(() => {
            _this.updateFilterState();
            _this.updateFilterOwner();
        });
    }

    private generateHTML() {
        this.generateStateHTML();
        this.generateOwnerHTML();
        this.addButtons();
    }

    private generateStateHTML() {
        const updateFunctions = [];

        const checkedFunction = (s) => {
            return AppComponents.Filters.isStateDisplayed(s);
        };

        const stateParent = document.createElement("div");
        for (const state in Localization.displayStrings.state) {
            if (Localization.displayStrings.state.hasOwnProperty(state) && state === String(+state)) {
                const _state = +state;

                const buttonElement = document.createElement("button") as HTMLButtonElement;

                buttonElement.onclick = () => {
                    if (AppComponents.Filters.isStateDisplayed(_state)) {
                        AppComponents.Filters.removeStateFromFilter(_state);
                    } else {
                        AppComponents.Filters.addStateToFilter(_state);
                    }
                };

                updateFunctions.push(() => {
                    const isOn = checkedFunction(_state);

                    buttonElement.classList.add(`checkbutton-${isOn ? "on" : "off"}`);
                    buttonElement.classList.remove(`checkbutton-${!isOn ? "on" : "off"}`);
                });

                PopupHelper.AddContentToStateButton(_state, buttonElement);

                stateParent.appendChild(buttonElement);
            }
        }

        this.updateFilterState = () => {
            updateFunctions.forEach((f) => f());
        };

        this.updateFilterState();

        this.AddTitleRow(Localization.displayStrings.popupFilter.state, "state", stateParent, true);
    }

    private generateOwnerHTML() {
        const ownerElement = document.createElement("div");

        const ownerModeElement = document.createElement("div");
        ownerModeElement.innerHTML = Localization.displayStrings.popupFilter.ownermodedescription;

        const ownerModeSeparatedButton = document.createElement("button") as HTMLButtonElement;
        ownerModeSeparatedButton.innerText = Localization.displayStrings.popupFilter.ownermodeseparated;

        const ownerModeGroupedButton = document.createElement("button") as HTMLButtonElement;
        ownerModeGroupedButton.innerText = Localization.displayStrings.popupFilter.ownermodegrouped;

        const switchOwnerGroupMode = (groupOn: boolean) => {
            AppComponents.Filters.OwnerGroupMode = groupOn;
        };

        ownerModeGroupedButton.onclick = () => {
            switchOwnerGroupMode(true);
        };
        ownerModeSeparatedButton.onclick = () => {
            switchOwnerGroupMode(false);
        };

        this.ownerParent = document.createElement("div");

        const filterQuickSwitchElement = document.createElement("div");

        ownerModeElement.appendChild(ownerModeSeparatedButton);
        ownerModeElement.appendChild(ownerModeGroupedButton);

        const updateOwnerMode = () => {
            ownerModeElement.style.display = AppComponents.ApplicationState.hasOwnerGroups ? "block" : "none";

            const groupOn = AppComponents.Filters.OwnerGroupMode;

            ownerModeGroupedButton.classList.add(`checkbutton-${groupOn ? "on" : "off"}`);
            ownerModeGroupedButton.classList.remove(`checkbutton-${!groupOn ? "on" : "off"}`);

            ownerModeSeparatedButton.classList.add(`checkbutton-${!groupOn ? "on" : "off"}`);
            ownerModeSeparatedButton.classList.remove(`checkbutton-${groupOn ? "on" : "off"}`);
        };

        AppComponents.Events.Owners.Change.on(updateOwnerMode);
        AppComponents.Events.Filter.Changed.on(updateOwnerMode);

        ownerElement.appendChild(ownerModeElement);
        ownerElement.appendChild(this.ownerParent);
        ownerElement.appendChild(filterQuickSwitchElement);

        this.AddTitleRow(Localization.displayStrings.popupFilter.owner, "state", ownerElement, true);
    }

    private addButtons() {
        const buttons = [];

        const closeFunction = () => {
            AppComponents.Filters.endFilterConfigurationMode();
        };

        const closeButtonClasses = ["popup-button-normal"];
        const abortButton = new PopupButton(closeFunction, Localization.displayStrings.popupFilter.close, "close", closeButtonClasses);
        buttons.push(abortButton);

        const filterSizeElement = document.createElement("span");
        const filterSizeDisplayed = document.createElement("span");
        const filterSizeAll = document.createElement("all");

        filterSizeElement.appendChild(document.createTextNode("("));
        filterSizeElement.appendChild(filterSizeDisplayed);
        filterSizeElement.appendChild(document.createTextNode(" / "));
        filterSizeElement.appendChild(filterSizeAll);
        filterSizeElement.appendChild(document.createTextNode(") "));

        const updateFilterSizes = () => {
            let sizeDisplayed = 0;
            let sizeAll = 0;
            AppComponents.ApplicationState.posterFeatures.forEach((feature) => {
                sizeAll++;
                if (AppComponents.Filters.posterDisplayedFunction(feature)) {
                    sizeDisplayed++;
                }
            });

            filterSizeDisplayed.innerText = String(sizeDisplayed);
            filterSizeAll.innerText = String(sizeAll);
        };

        AppComponents.Events.Filter.Changed.on(() => {
            updateFilterSizes();
        });

        AppComponents.Map.posterVectorSource.on("change", () => {
            updateFilterSizes();
        });

        this.AddButtons("buttons", buttons, filterSizeElement);
    }

    private buildOwnerFilterHTML() {
        if (AppComponents.Filters.OwnerGroupMode) {
            this.buildOwnerGroupsFilterHTML();
        } else {
            this.buildSingleOwnerFilterHTML();
        }
    }

    private buildSingleOwnerFilterHTML() {
        const ownersArray = Array.from(AppComponents.ApplicationState.owners);
        ownersArray.sort();

        const onclickFunction = (owner) => {
            if (AppComponents.Filters.isOwnerDisplayed(owner)) {
                AppComponents.Filters.removeOwner(owner);
            } else {
                AppComponents.Filters.addOwner(owner);
            }
        };

        const checkedFunction = (owner) => {
            return AppComponents.Filters.isOwnerDisplayed(owner);
        };

        this.buildOwnerFilterBase(ownersArray, onclickFunction, checkedFunction);
    }

    private buildOwnerGroupsFilterHTML() {
        const ownerGroupsArray = Array.from(AppComponents.ApplicationState.ownerGroups);
        ownerGroupsArray.sort();

        for (let i = 0; i < ownerGroupsArray.length; i++) {
            ownerGroupsArray[i] = ownerGroupsArray[i].replace(",", ", ");
        }

        const onclickFunction = (ownerGroup) => {
            if (AppComponents.Filters.isOwnerGroupDisplayed(ownerGroup)) {
                AppComponents.Filters.removeOwnerGroupFromFilter(ownerGroup);
            } else {
                AppComponents.Filters.addOwnerGroupToFilter(ownerGroup);
            }
        };

        const checkedFunction = (ownerGroup) => {
            return AppComponents.Filters.isOwnerGroupDisplayed(ownerGroup);
        };

        this.buildOwnerFilterBase(ownerGroupsArray, onclickFunction, checkedFunction);
    }

    private buildOwnerFilterBase(elements: string[], onclickFunction: (o) => void, checkedFunction: (o) => boolean) {
        while (this.ownerParent.hasChildNodes()) {
            this.ownerParent.removeChild(this.ownerParent.firstChild);
        }

        const updateFunctions = [];

        let noOwnerElementPresent = false;
        const elementWithNoOwnerAtEnd = [];
        elements.forEach((e) => {
            if (e === "") {
                noOwnerElementPresent = true;
            } else {
                elementWithNoOwnerAtEnd.push(e);
            }
        });
        if (noOwnerElementPresent) {
            elementWithNoOwnerAtEnd.push("");
        }

        elementWithNoOwnerAtEnd.forEach((owner) => {
            const _owner = owner;

            const buttonElement = document.createElement("button") as HTMLButtonElement;
            buttonElement.value = owner;
            buttonElement.innerText = owner !== "" ? owner : Localization.displayStrings.popupFilter.noowner;

            buttonElement.onclick = () => onclickFunction(_owner);

            updateFunctions.push(() => {
                const isOn = checkedFunction(_owner);

                buttonElement.classList.add(`checkbutton-${isOn ? "on" : "off"}`);
                buttonElement.classList.remove(`checkbutton-${!isOn ? "on" : "off"}`);
            });

            this.ownerParent.appendChild(buttonElement);
        });

        this.updateFilterOwner = () => {
            updateFunctions.forEach((f) => f());
        };

        this.updateFilterOwner();
    }

}
