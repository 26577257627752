import { toLonLat } from "ol/proj.js";
import { String } from "typescript-string-operations";
import { Request } from "./backend";
import AppComponents from "./components";
import CSSConstants from "./cssconstants";
import Localization from "./localize";
import { EPopupContent } from "./popup/popup";
import ListPage from "./ui/listpage";
import { CreateBaseUrlWithParam, UrlParams } from "./url";

export default class Admin {

    public get IsAdmin(): boolean {
        return this.isAdmin;
    }

    public set IsAdmin(mode: boolean) {
        this.isAdmin = mode;
    }

    public get IsSuperuser(): boolean {
        return this.isSuperuser;
    }

    public set IsSuperuser(mode: boolean) {
        this.isSuperuser = mode;
    }

    private isAdmin = false;
    private isSuperuser = false;

    private readonly centerElement = document.getElementById("center");

    constructor() {
        this.registerEvents();
    }

    public displayAdminpage(): void {
        AppComponents.Subpage.showFlexible(() => this.createUserpage(AppComponents.User.Username, this.IsSuperuser));
    }

    public mapCenterEnd(): void {
        AppComponents.Popup.showPopup(false);
        this.displayAdminpage();
        AppComponents.Controls.enablePosterMenuControls();
        AppComponents.Map.posterLayer.setVisible(true);
        AppComponents.Map.clusterLayer.setVisible(true);
        AppComponents.Map.posterSubmitLayer.setVisible(true);
        this.centerElement.classList.add(CSSConstants.hidden);
    }

    public setMapCenter(coordinates: ol.Coordinate): void {
        const request = new Request();
        request.operation = "setcenter";

        const lonlat = toLonLat(coordinates);

        request.params.coordinates = `${Math.round(lonlat[1] * 100000) / 100000},${Math.round(lonlat[0] * 100000) / 100000}`;

        request.responseFunctions[200] = () => {
            AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.mapCenter.messageSuccess);
        };

        request.responseFunctions[403] = (req) => {
            const warningText = Localization.displayStrings.admin.error[req.responseText] as string;
            if (warningText !== "") {
                AppComponents.Overlays.DisplayWarning(warningText);
            }
        };

        AppComponents.Backend.sendRequest(request);
    }

    private registerEvents(): void {
        const _this = this;
        AppComponents.Events.User.Login.on(() => {
            if (_this.isAdmin && AppComponents.User.UserPasswordSet) {
                AppComponents.Subpage.showFlexible(() => _this.createUserpage(AppComponents.User.Username, this.isSuperuser));
            }
        });
    }

    private createUserpage(username: string, isSuperuser: boolean, supervisedUsers?: SupervisedUsers): [string, HTMLElement] {
        const listPage = new ListPage();

        const isMainMenu = username === AppComponents.User.Username;

        listPage.add(Localization.displayStrings.admin.username, undefined, username, "account");

        const emailAddressElement = document.createElement("div");
        emailAddressElement.innerText = isMainMenu ? AppComponents.User.EMail : supervisedUsers.getUserData(username, EUserFieldName.email);

        listPage.add(Localization.displayStrings.admin.email.title, () => {
            this.displayEmailPage(username, emailAddressElement.innerText, (newValue) => {
                if (isMainMenu) {
                    AppComponents.User.EMail = newValue;
                } else {
                    supervisedUsers.setUserData(username, EUserFieldName.email, newValue);
                }
            });
        }, emailAddressElement, "at");

        const passwordDescription = isMainMenu && !AppComponents.User.UserPasswordSet ?
            Localization.displayStrings.admin.password.descriptionUnset :
            Localization.displayStrings.admin.password.description;

        listPage.add(Localization.displayStrings.admin.password.title,
            () => this.displayPasswordPage(false, username), passwordDescription, "key");

        listPage.add(Localization.displayStrings.admin.adminPassword.title,
            () => this.displayPasswordPage(true, username), Localization.displayStrings.admin.adminPassword.description, "shield-key");

        if (isMainMenu) {
            listPage.add(Localization.displayStrings.admin.mapCenter.title,
                () => this.mapCenterStart(),
                Localization.displayStrings.admin.mapCenter.description, "home");

            if (isSuperuser) {
                listPage.add(Localization.displayStrings.admin.addUser.title,
                    () => this.displayAddUserPage(), Localization.displayStrings.admin.addUser.description, "account-plus");
            }
        }

        let supervisedUsers1: SupervisedUsers;
        const listUserListElement = listPage.add(Localization.displayStrings.admin.superwiseUser.title,
            () => AppComponents.Subpage.showFlexible(() => this.createSupervisorPage(supervisedUsers1)),
            Localization.displayStrings.admin.superwiseUser.description, "account-supervisor");
        listUserListElement.classList.add(CSSConstants.hidden);

        const deleteUserListElement = listPage.add(Localization.displayStrings.admin.delete.title,
            () => this.displayDeleteUserPage(username), Localization.displayStrings.admin.delete.description, "delete_red");

        if (isSuperuser) {
            listUserListElement.classList.remove(CSSConstants.hidden);
            listUserListElement.classList.add(CSSConstants.disabled);

            deleteUserListElement.classList.add(CSSConstants.disabled);

            const getSupervisedUser = new Request();
            getSupervisedUser.operation = "listsuperviseduser";
            getSupervisedUser.params.foruser = username;

            getSupervisedUser.responseFunctions[200] = (req) => {
                const supervisedUsersXML = req.responseXML;
                supervisedUsers1 = new SupervisedUsers(supervisedUsersXML);

                if (supervisedUsers1.any) {
                    listUserListElement.classList.remove(CSSConstants.disabled);
                } else {
                    deleteUserListElement.classList.remove(CSSConstants.disabled);
                }
            };

            AppComponents.Backend.sendRequest(getSupervisedUser);
        }

        const title = isMainMenu ? Localization.displayStrings.admin.title : String.Format(Localization.displayStrings.admin.titleSupervise, username);
        return [title, listPage.Content];
    }

    private displayEmailPage(username: string, currentEmail: string, successOperation: (newAddress: string) => void): void {
        const parent = document.createElement("div");
        parent.classList.add(CSSConstants.settings);
        parent.innerText = Localization.displayStrings.admin.email.help;

        const changeEmailButton = new SubmitButton("setemail", Localization.displayStrings.admin.email.modifyButton);
        changeEmailButton.addParameter("username", () => username);

        const newEmailInput = this.generateInputHTML(String.Format(Localization.displayStrings.admin.email.new, username), "text", parent);
        newEmailInput.value = currentEmail;
        changeEmailButton.addParameter("email", () => newEmailInput.value);

        this.addCurrentAdminPassword(parent, changeEmailButton);
        this.addUserParameter(username, changeEmailButton);

        changeEmailButton.onSuccess = () => {
            successOperation(newEmailInput.value);
            AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.email.messageSuccess);
        };

        parent.appendChild(changeEmailButton.ButtonElement);

        AppComponents.Subpage.showStatic(Localization.displayStrings.admin.email.header, parent);
    }

    private displayPasswordPage(admin: boolean, username: string): void {
        const header = admin ? Localization.displayStrings.admin.adminPassword.header : Localization.displayStrings.admin.password.header;
        const operation = admin ? "setadminpassword" : "setpassword";
        const passwordLabelText = String.Format(admin ? Localization.displayStrings.admin.adminPassword.new : Localization.displayStrings.admin.password.new,
            username);
        const passwordRepeatLabelText = admin ? Localization.displayStrings.admin.adminPassword.repeat : Localization.displayStrings.admin.password.repeat;

        const parent = document.createElement("div");
        parent.classList.add(CSSConstants.settings);

        const changePasswordButton = new SubmitButton(operation, Localization.displayStrings.admin.password.modifyButton);

        const passwordInputElement = this.generateInputHTML(passwordLabelText, "password", parent);
        const passwordRepeatInputElement = this.generateInputHTML(passwordRepeatLabelText, "password", parent);
        changePasswordButton.addParameter("newpassword", () => passwordInputElement.value, () => {
            if (passwordInputElement.value.length < 8) { return Localization.displayStrings.admin.error.passwordTooShort; }
            if (passwordInputElement.value !== passwordRepeatInputElement.value) { return Localization.displayStrings.admin.error.passwordRepeatNotIdentical; }
            return undefined;
        });

        this.addCurrentAdminPassword(parent, changePasswordButton);
        this.addUserParameter(username, changePasswordButton);

        changePasswordButton.onSuccess = () => {
            if (username === AppComponents.User.Username) {
                if (admin) {
                    AppComponents.User.Password = passwordInputElement.value;
                }
                AppComponents.User.UserPasswordSet = true;
            }
            AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.password.messageSuccess);
        };

        parent.appendChild(changePasswordButton.ButtonElement);

        AppComponents.Subpage.showStatic(header, parent);
    }

    private displayDeleteUserPage(username: string): void {
        const parent = document.createElement("div");
        parent.classList.add(CSSConstants.settings);

        const deleteUserButton = new SubmitButton("deleteuser", String.Format(Localization.displayStrings.admin.delete.deleteUserButton, username));

        this.addCurrentAdminPassword(parent, deleteUserButton);
        this.addUserParameter(username, deleteUserButton);

        deleteUserButton.onSuccess = () => {
            if (username === AppComponents.User.Username) {
                AppComponents.User.Logout(false);
            }
            AppComponents.Subpage.back();
            AppComponents.Subpage.back();
            AppComponents.Subpage.back();

            AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.delete.messageSuccess);
        };

        parent.appendChild(deleteUserButton.ButtonElement);

        AppComponents.Subpage.showStatic(String.Format(Localization.displayStrings.admin.delete.header, username), parent);
    }

    private displayAddUserPage(): void {
        const parent = document.createElement("div");
        parent.classList.add(CSSConstants.settings);
        const addUserButton = new SubmitButton("adduser", Localization.displayStrings.admin.addUser.adduserButton);

        // Trimmed username in lowercase, calculated when username is checked for validity
        let normalizedUsername: string;

        const usernameInput = this.generateInputHTML(Localization.displayStrings.admin.addUser.username, "text", parent);
        addUserButton.addParameter("username", () => normalizedUsername, () => {
            normalizedUsername = usernameInput.value.toLowerCase().trim();
            if (normalizedUsername.length < 3) {
                return Localization.displayStrings.admin.error.usernameTooShort;
            }
            if (normalizedUsername.length > 50) {
                return Localization.displayStrings.admin.error.usernameTooLong;
            }

            return undefined;
        });

        const password = this.getPassword();
        addUserButton.addParameter("password", () => password);

        const emailInput = this.generateInputHTML(Localization.displayStrings.admin.addUser.email, "text", parent);
        addUserButton.addParameter("email", () => emailInput.value);

        const lonlat = toLonLat(Localization.MapCenter);
        addUserButton.addParameter("center", () => `${Math.round(lonlat[1] * 100000) / 100000},${Math.round(lonlat[0] * 100000) / 100000}`);

        const isSuperuserParent = document.createElement("div");
        const isSuperuserButton = document.createElement("button") as HTMLButtonElement;
        isSuperuserButton.innerText = Localization.displayStrings.admin.addUser.isSuperuser;

        let isSuperUser = false;
        isSuperuserButton.classList.add("checkbutton-off");

        isSuperuserButton.onclick = () => {
            isSuperUser = !isSuperUser;
            isSuperuserButton.classList.add(`checkbutton-${isSuperUser ? "on" : "off"}`);
            isSuperuserButton.classList.remove(`checkbutton-${!isSuperUser ? "on" : "off"}`);
        };

        isSuperuserParent.appendChild(isSuperuserButton);
        parent.appendChild(isSuperuserParent);

        addUserButton.addParameter("issuperuser", () => isSuperUser ? "1" : "0");

        addUserButton.onSuccess = () => {
            AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.addUser.messageSuccess);

            const messageTextArea = document.createElement("textarea");

            let invitationMessage = Localization.displayStrings.admin.addUser.invitation.intro;
            invitationMessage += Localization.displayStrings.admin.addUser.invitation.url;

            const urlParams: Array<[UrlParams, string]> = [];
            urlParams.push([UrlParams.user, normalizedUsername]);
            invitationMessage += CreateBaseUrlWithParam(urlParams);
            invitationMessage += Localization.displayStrings.admin.addUser.invitation.username + normalizedUsername;
            invitationMessage += Localization.displayStrings.admin.addUser.invitation.password + password;
            invitationMessage += Localization.displayStrings.admin.addUser.invitation.outro;

            messageTextArea.value = invitationMessage;

            const copyButton = document.createElement("button");

            copyButton.innerText = Localization.displayStrings.admin.addUser.copyButton;
            copyButton.onclick = () => {
                messageTextArea.select();
                document.execCommand("copy");
                AppComponents.Overlays.DisplayInfo(Localization.displayStrings.admin.addUser.messageCopy);
            };
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
            parent.appendChild(messageTextArea);
            parent.appendChild(copyButton);
        };

        this.addCurrentAdminPassword(parent, addUserButton);

        parent.appendChild(addUserButton.ButtonElement);

        AppComponents.Subpage.showStatic(Localization.displayStrings.admin.addUser.title, parent);
    }

    private createSupervisorPage(supervisedUsers: SupervisedUsers): [string, HTMLElement] {
        const listPage = new ListPage();

        const userNames = supervisedUsers.usernames;
        userNames.sort();

        userNames.forEach((userName) => {
            const descriptionElement = document.createElement("div");
            const email = supervisedUsers.getUserData(userName, EUserFieldName.email);
            if (email && email.length > 0) {
                const emailElement = document.createElement("div");
                emailElement.innerText = email;
                descriptionElement.appendChild(emailElement);
            }
            const poster = supervisedUsers.getUserData(userName, EUserFieldName.poster);
            if (poster && poster.length > 0) {
                const posterElement = document.createElement("div");
                posterElement.innerText = String.Format(Localization.displayStrings.admin.superwiseUser.poster, poster);
                descriptionElement.appendChild(posterElement);
            }
            const isSuperuser = supervisedUsers.getUserData(userName, EUserFieldName.superuser) === "true";

            if (isSuperuser) {
                const subuser = supervisedUsers.getUserData(userName, EUserFieldName.subusers);
                if (subuser && subuser.length > 0) {
                    const subuserElement = document.createElement("div");
                    subuserElement.innerText = String.Format(Localization.displayStrings.admin.superwiseUser.subuser, subuser);
                    descriptionElement.appendChild(subuserElement);
                }
            }

            listPage.add(userName, () => {
                AppComponents.Subpage.showFlexible(() => this.createUserpage(userName, isSuperuser, supervisedUsers));
            }, descriptionElement, "account-details");
        });

        return [Localization.displayStrings.admin.superwiseUser.title, listPage.Content];
    }

    private addCurrentAdminPassword(parent: HTMLElement, button: SubmitButton) {
        const currentAdminPasswordInput = this.generateInputHTML(
            String.Format(Localization.displayStrings.admin.currentAdminPassword, AppComponents.User.Username),
            "password", parent);
        button.addParameter("currentadminpassword", () => currentAdminPasswordInput.value);
    }

    private addUserParameter(username: string, button: SubmitButton) {
        button.addParameter("foruser", () => username);
    }

    private generateInputHTML(label: string, type: string, parent: HTMLElement): HTMLInputElement {
        const id = Math.random().toString(36).slice(2);

        const inputParentElement = document.createElement("div");

        const labelElement = document.createElement("label");
        labelElement.innerText = label + ":";
        labelElement.htmlFor = id;
        parent.appendChild(labelElement);

        const inputElement = document.createElement("input");
        inputElement.id = id;

        switch (type) {
            case "text":
            case "password":
                labelElement.classList.add("heading");
                inputElement.type = type;
                inputParentElement.appendChild(labelElement);
                inputParentElement.appendChild(inputElement);
                break;
            case "checkbox":
                inputElement.type = type;
                inputParentElement.appendChild(inputElement);
                inputParentElement.appendChild(labelElement);
                break;
        }

        parent.appendChild(inputParentElement);

        return inputElement;
    }

    private getPassword(): string {
        return Math.random().toString(36).slice(7) + "-" + Math.random().toString(36).slice(7) + "-" + Math.random().toString(36).slice(7);
    }

    private mapCenterStart(): void {
        AppComponents.Subpage.hide();
        AppComponents.Controls.disablePosterMenuControls();
        AppComponents.Map.posterLayer.setVisible(false);
        AppComponents.Map.clusterLayer.setVisible(false);
        AppComponents.Map.posterSubmitLayer.setVisible(false);

        this.centerElement.classList.remove(CSSConstants.hidden);

        AppComponents.Popup.showPopup(true, EPopupContent.MapCenter);
    }
}

class SubmitButton {

    public get ButtonElement(): HTMLButtonElement {
        return this.submitButtonElement;
    }

    public set onSuccess(successFunction: (req: XMLHttpRequest) => void) {
        this.onSuccessFunction = successFunction;
    }
    private readonly submitButtonElement = document.createElement("button");
    private readonly parameters: Array<[string, () => string, () => string]> = [];
    private onSuccessFunction: (req: XMLHttpRequest) => void;

    constructor(operation: string, label: string) {
        const _this = this;
        this.submitButtonElement.innerText = label;
        this.submitButtonElement.onclick = () => {
            _this.onClickFunction(operation);
        };
    }

    public addParameter(name: string, valueFunction: () => string, checkFunction?: () => string): void {
        this.parameters.push([name, valueFunction, checkFunction]);
    }

    private onClickFunction(operation: string): void {
        const request = new Request();
        request.operation = operation;

        let hasErrors = false;
        this.parameters.forEach((parameter) => {
            if (hasErrors) {
                return;
            }

            // Check if the value is valid: error check function is either not set or returns nothing
            if (!parameter[2] || !parameter[2]()) {
                request.params[parameter[0]] = parameter[1]();
            } else {
                hasErrors = true;
                this.error(parameter[2]());
            }
        });

        if (hasErrors) {
            return;
        }

        request.responseFunctions[200] = (req) => {
            this.submitButtonElement.classList.replace(CSSConstants.pending, CSSConstants.active);
            if (this.onSuccessFunction) {
                this.onSuccessFunction(req);
            }

            window.setTimeout(() => {
                this.submitButtonElement.classList.remove(CSSConstants.active);
            }, 2000);
        };

        request.responseFunctions[403] = (req) => {
            const warningText = Localization.displayStrings.admin.error[req.responseText] as string;
            this.error(warningText);
        };

        this.submitButtonElement.classList.add(CSSConstants.pending);
        this.submitButtonElement.classList.remove(CSSConstants.error);
        AppComponents.Backend.sendRequest(request);
    }

    private error(warningText: string) {
        const _this = this;
        if (warningText !== "") {
            AppComponents.Overlays.DisplayWarning(warningText);
        }
        this.submitButtonElement.classList.remove(CSSConstants.pending);
        this.submitButtonElement.classList.add(CSSConstants.error);

        window.setTimeout(() => {
            _this.submitButtonElement.classList.remove(CSSConstants.error);
        }, 2000);
    }
}

class SupervisedUsers {
    private anySupervisedUser = false;
    private readonly supervisedUsers: Array<{ [name: string]: Element }> = [];

    constructor(supervisedUsersXML: Document) {

        const userElements = Array.from(supervisedUsersXML.getElementsByTagName("user"));

        userElements.forEach((userElement) => {
            this.anySupervisedUser = true;
            const name = userElement.getElementsByTagName(EUserFieldName.name)[0].textContent;
            this.supervisedUsers[name] = userElement;

        });
    }

    public get usernames(): string[] {
        return Object.keys(this.supervisedUsers);
    }

    public get any(): boolean {
        return this.anySupervisedUser;
    }

    public getUserData(username: string, fieldName: EUserFieldName): string {
        const userElement = this.getUserElement(username, fieldName);
        return userElement ? userElement.textContent : undefined;
    }

    public setUserData(username: string, fieldName: EUserFieldName, value: string): void {
        const userElement = this.getUserElement(username, fieldName);
        if (userElement) {
            userElement.textContent = value;
        }
    }

    private getUserElement(username: string, fieldName: EUserFieldName): Element {
        const userElement = this.supervisedUsers[username] as Element;
        if (!userElement) {
            return undefined;
        }

        const dataElements = userElement.getElementsByTagName(fieldName);
        if (dataElements.length !== 1) {
            return undefined;
        }

        return dataElements[0];
    }
}

enum EUserFieldName {
    name = "name",
    email = "email",
    poster = "poster",
    superuser = "superuser",
    subusers = "subusers"
}
