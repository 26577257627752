import Admin from "./admin";
import Backend from "./backend";
import Events from "./events";
import Filters from "./filter";
import Help from "./help";
import Localization from "./localize";
import Map from "./map";
import Popup from "./popup/popup";
import PosterSync from "./postersync";
import ApplicationState from "./state";
import Controls from "./ui/controls";
import Menu from "./ui/menu";
import Overlays from "./ui/overlays";
import Subpage from "./ui/subpage";
import User from "./user";

export default class AppComponents {
    private static localization: Localization;
    private static events: Events;
    private static overlays: Overlays;
    private static menu: Menu;
    private static subpage: Subpage;
    private static state: ApplicationState;
    private static filter: Filters;
    private static user: User;
    private static backend: Backend;
    private static controls: Controls;
    private static map: Map;
    private static popup: Popup;
    private static postersync: PosterSync;
    private static help: Help;
    private static admin: Admin;

    public static get Localization(): Localization {
        if (!AppComponents.localization) {
            AppComponents.localization = new Localization();
        }
        return AppComponents.localization;
    }

    public static get Events(): Events {
        if (!AppComponents.events) {
            AppComponents.events = new Events();
        }
        return AppComponents.events;
    }

    public static get Overlays(): Overlays {
        if (!AppComponents.overlays) {
            AppComponents.overlays = new Overlays();
        }
        return AppComponents.overlays;
    }

    public static get Menu(): Menu {
        if (!AppComponents.menu) {
            AppComponents.menu = new Menu();
        }
        return AppComponents.menu;
    }

    public static get Subpage(): Subpage {
        if (!AppComponents.subpage) {
            AppComponents.subpage = new Subpage();
        }
        return AppComponents.subpage;
    }

    public static get ApplicationState(): ApplicationState {
        if (!AppComponents.state) {
            AppComponents.state = new ApplicationState();
        }
        return AppComponents.state;
    }

    public static get Filters(): Filters {
        if (!AppComponents.filter) {
            AppComponents.filter = new Filters();
        }
        return AppComponents.filter;
    }

    public static get User(): User {
        if (!AppComponents.user) {
            AppComponents.user = new User();
        }
        return AppComponents.user;
    }


    public static get Backend(): Backend {
        if (!AppComponents.backend) {
            AppComponents.backend = new Backend();
        }
        return AppComponents.backend;
    }

    public static get Controls(): Controls {
        if (!AppComponents.controls) {
            AppComponents.controls = new Controls();
        }
        return AppComponents.controls;
    }

    public static get Map(): Map {
        if (!AppComponents.map) {
            AppComponents.map = new Map();
        }
        return AppComponents.map;
    }

    public static get Popup(): Popup {
        if (!AppComponents.popup) {
            AppComponents.popup = new Popup();
        }
        return AppComponents.popup;
    }

    public static get PosterSync(): PosterSync {
        if (!AppComponents.postersync) {
            AppComponents.postersync = new PosterSync();
        }
        return AppComponents.postersync;
    }

    public static get Help(): Help {
        if (!AppComponents.help) {
            AppComponents.help = new Help();
        }
        return AppComponents.help;
    }

    public static get Admin(): Admin {
        if (!AppComponents.admin) {
            AppComponents.admin = new Admin();
        }
        return AppComponents.admin;
    }
}

