import CSSConstants from "../cssconstants";

export default class Overlays {

    private displayHelp = false;
    private displayMessage = false;
    private displaySpinner = false;

    private helpTimeout: number;
    private messageTimeout: number;
    private spinnerTimeout: number;

    private readonly helpElement = document.getElementById("help");
    private readonly helpMessageElement = document.getElementById("help-message");
    private readonly helpCloseElement = document.getElementById("help-close");

    private readonly messageElement = document.getElementById("message-bar");
    private readonly messageMessageElement = document.getElementById("message");
    private readonly messageMessageDetailElement = document.getElementById("message-detail");
    private readonly messageCloseElement = document.getElementById("message-close");

    private readonly spinnerElement = document.getElementById("spinner");

    constructor() {
        const _this = this;
        this.helpCloseElement.onclick = () => {
            if (this.helpTimeout) {
                window.clearTimeout(this.helpTimeout);
            }
            _this.HideHelp();
        };
        this.messageCloseElement.onclick = () => {
            if (this.messageTimeout) {
                window.clearTimeout(this.messageTimeout);
            }
            _this.HideMessage();
        };
    }

    public DisplayHelp(message: string): void {
        this.helpMessageElement.innerText = message;

        this.helpElement.classList.remove(CSSConstants.hidden);
        this.displayHelp = true;
        this.setHelpTimeout();
    }

    public HideHelp(): void {
        if (this.helpTimeout) {
            window.clearTimeout(this.helpTimeout);
        }
        this.helpElement.classList.add(CSSConstants.hidden);
    }

    public DisplayInfo(message: string, messageDetail?: string) {
        this.DisplayMessage(EMessageSeverity.Info, message, messageDetail);
    }

    public DisplayWarning(message: string, messageDetail?: string) {
        this.DisplayMessage(EMessageSeverity.Warning, message, messageDetail);
    }

    private DisplayMessage(severity: EMessageSeverity, message: string, messageDetail: string) {

        this.messageElement.classList.remove(CSSConstants.warning);
        this.messageElement.classList.remove(CSSConstants.info);

        switch (severity) {
            case EMessageSeverity.Info:
                this.messageElement.classList.add(CSSConstants.info);
                break;
            case EMessageSeverity.Warning:
                this.messageElement.classList.add(CSSConstants.warning);
                break;
        }

        this.displayMessage = true;

        this.messageMessageElement.innerText = message;
        if (messageDetail) {
            this.messageMessageDetailElement.innerText = messageDetail;
        } else {
            this.messageMessageDetailElement.innerText = "";
        }

        this.messageElement.classList.remove(CSSConstants.hidden);
        this.HideSpinner(true);
        this.setMessageTimeout();
    }

    public HideMessage(): void {
        this.displayMessage = false;
        this.messageElement.classList.add(CSSConstants.hidden);

        if (this.displaySpinner) {
            this.ShowSpinner();
        }
    }

    public ShowSpinner() {
        this.displaySpinner = true;

        if (this.displayMessage) {
            return;
        }

        if (this.spinnerTimeout) {
            window.clearTimeout(this.spinnerTimeout);
        }

        this.spinnerElement.classList.remove(CSSConstants.hidden);
    }

    public HideSpinner(atOnce?: boolean): void {
        const _this = this;
        if (atOnce) {
            this.displaySpinner = false;
            this.spinnerElement.classList.add(CSSConstants.hidden);
        }
        else {
            this.spinnerTimeout = window.setTimeout(() => {
                _this.HideSpinner(true);
            }, 1250);
        }
    }

    private setHelpTimeout(): void {
        const _this = this;
        if (this.helpTimeout) {
            window.clearTimeout(this.helpTimeout);
        }
        this.helpTimeout = window.setTimeout(() => {
            _this.helpElement.classList.add(CSSConstants.hidden);
            _this.displayHelp = false;
        }, 7000);
    }

    private setMessageTimeout() {
        const _this = this;
        if (this.messageTimeout) {
            window.clearTimeout(this.messageTimeout);
        }
        this.messageTimeout = window.setTimeout(() => {
            _this.HideMessage();
        }, 7000);
    }
}

enum EMessageSeverity {
    Info = 0,
    Warning = 1
}
