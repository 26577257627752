import Control from "ol/control/Control";
import Rotate from "ol/control/Rotate";
import { CLASS_CONTROL, CLASS_HIDDEN, CLASS_UNSELECTABLE } from "ol/css.js";
import { TOUCH as browserHasTouch } from "ol/has";
import AppComponents from "../components";
import CSSConstants from "../cssconstants";
import Localization from "../localize";
import SVGLoader from "../svgloader";

export default class Controls {

    private readonly customControls: CustomButtonControl[] = [];
    private readonly posterControls: CustomButtonControl[] = [];

    public addControls(): void {
        this.addPopupControl();
        this.addMenuControl();
        this.addGeolocationControl();
        this.addAddPosterControl();
        this.addHelpControl();
        this.addFilterPosterControl();
        this.addRotateControl();
    }

    public disablePosterMenuControls(): void {
        this.posterControls.forEach((control) => {
            control.Disable();
        });
    }

    public enablePosterMenuControls(): void {
        this.posterControls.forEach((control) => {
            control.Enable();
        });
    }

    private addPopupControl() {

        const ctrl = new Control({
            element: document.getElementById("popup")
        });

        AppComponents.Map.map.addControl(ctrl);
    }

    private addMenuControl() {
        // Control to open the main menu

        const id = "menu";
        const title = Localization.displayStrings.buttons.Menu;
        const clickFunction = () => AppComponents.Menu.show();

        const ctrl = new CustomButtonControl(id, ButtonPosition.TopLeft, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);
        this.customControls.push(ctrl);
        this.posterControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement }));
    }

    private addGeolocationControl() {
        // Control to start/stop geolocation

        const id = "geolocation";
        const title = Localization.displayStrings.buttons.Geolocation;
        const clickFunction = () => AppComponents.Map.geolocation.setTracking(!AppComponents.Map.geolocation.getTracking());

        const ctrl = new CustomButtonControl(id, ButtonPosition.TopLeft, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);
        this.customControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement }));

        AppComponents.Map.geolocation.on("change:tracking", () => {
            const dot = document.getElementById("geolocation-icon-dot");

            if (AppComponents.Map.geolocation.getTracking()) {
                if (AppComponents.Map.geolocation.getAccuracy() <= AppComponents.Map.requiredAccuracy) {
                    ctrl.SetState(ButtonState.Active);
                    dot.classList.add("blink");

                } else {
                    ctrl.SetState(ButtonState.Pending);
                    dot.classList.add("blink");
                }
            } else {
                ctrl.SetState(ButtonState.Normal);
                dot.classList.remove("blink");
            }
        });

        AppComponents.Map.geolocation.on("error", () => {
            AppComponents.Overlays.DisplayWarning(Localization.displayStrings.geolocationError);
            ctrl.SetState(ButtonState.Error);
            const dot = document.getElementById("geolocation-icon-dot");
            dot.classList.remove("blink");
        });
    }

    private addAddPosterControl() {
        // Control to add a poster

        const id = "map-marker-add";
        const title = Localization.displayStrings.buttons["Add Poster"];
        const clickFunction = () => AppComponents.ApplicationState.toggleAddMode();

        const ctrl = new CustomButtonControl(id, ButtonPosition.TopLeft, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);
        this.customControls.push(ctrl);
        this.posterControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement }));

        AppComponents.Events.AddMode.Start.on(() => {
            ctrl.SetState(ButtonState.Active);
            AppComponents.Overlays.DisplayHelp(Localization.displayStrings.info["Add Poster"]);
        });
        AppComponents.Events.AddMode.End.on(() => {
            ctrl.SetState(ButtonState.Normal);
            AppComponents.Overlays.HideHelp();
        });
    }

    private addFilterPosterControl() {
        // Control to filter poster

        const id = "filter";
        const title = Localization.displayStrings.buttons["Filter Poster"];
        const clickFunction = () => AppComponents.Filters.toggleFilterConfigurationMode();

        const ctrl = new CustomButtonControl(id, ButtonPosition.BottomRight, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);
        this.customControls.push(ctrl);
        this.posterControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement }));

        AppComponents.Events.Filter.On.on(() => ctrl.SetState(ButtonState.Active));
        AppComponents.Events.Filter.Off.on(() => ctrl.SetState(ButtonState.Normal));

        AppComponents.Events.User.Login.on(() => ctrl.Disable());

        AppComponents.Events.Owners.Change.on(() => {
            if (AppComponents.ApplicationState.posterFeatures.getArray().length === 0) {
                ctrl.Disable();
            } else {
                ctrl.Enable();
            }
        });
    }

    private addHelpControl() {
        const _this = this;
        let messageTimeout: number;

        let showHelp = false;

        const id = "help";
        const title = Localization.displayStrings.buttons.Help;
        // const clickFunction = () => _this.help.DisplayHelp();
        const clickFunction = () => {
            if (messageTimeout) {
                window.clearTimeout(messageTimeout);
            }

            showHelp = !showHelp;
            _this.customControls.forEach((c1) => {
                const c = c1 as CustomButtonControl;
                c.DisplayHelp(showHelp, true);
            });
            ctrl.SetState(showHelp ? ButtonState.Active : ButtonState.Normal);
        };

        const ctrl = new CustomButtonControl(id, ButtonPosition.BottomRight, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);
        this.customControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement }));

        window.setTimeout(() => {
            clickFunction();
            messageTimeout = window.setTimeout(() => {
                clickFunction();
            }, 5000);
        }, 500);


    }

    private addRotateControl() {

        AppComponents.Map.map.addControl(new Rotate({}));

        const id = "rotate";
        const title = Localization.displayStrings.buttons.Rotate;
        const clickFunction = () => {
            AppComponents.Map.map.getView().animate({ rotation: 0 });
        };

        const ctrl = new CustomButtonControl(id, ButtonPosition.BottomRight, title, clickFunction);
        ctrl.SetState(ButtonState.Normal);

        function renderFunction() {
            if (AppComponents.Map.map.getView().getRotation() !== 0) {
                ctrl.BaseElement.classList.remove(CLASS_HIDDEN);
            } else {
                ctrl.BaseElement.classList.add(CLASS_HIDDEN);
            }
        }

        this.customControls.push(ctrl);
        AppComponents.Map.map.addControl(new Control({ element: ctrl.BaseElement, render: renderFunction }));
    }
}


class CustomButtonControl {

    public get BaseElement() { return this.baseElement; }

    public get ButtonElement() { return this.buttonElement; }

    public static get TopLeftVerticalPosition() {
        const value = CustomButtonControl.topLeftVerticalPosition;
        CustomButtonControl.topLeftVerticalPosition += CustomButtonControl.MarginBetweenButtons;
        return value;
    }

    public static get BottomRightVerticalPosition() {
        const value = CustomButtonControl.bottomRightVerticalPosition;
        CustomButtonControl.bottomRightVerticalPosition += CustomButtonControl.MarginBetweenButtons;
        return value;
    }

    private static topLeftVerticalPosition = 0.5;
    private static bottomRightVerticalPosition = 0.5;
    private static HorizontalPosition = 0.5;
    private static ButtonSize = browserHasTouch ? 2 : 2;
    private static MarginBetweenButtons = CustomButtonControl.ButtonSize + 0.3;

    private baseElement: HTMLElement;

    private helpForced = false;

    private helpElement: HTMLDivElement;


    private buttonElement: HTMLButtonElement;

    private svgIconElement: SVGElement;

    private iconColours: { [className: string]: string };

    constructor(id: string, position: ButtonPosition, title: string, clickFunction: () => void) {
        this.baseElement = document.createElement("div");

        this.BaseElement.id = `${id}-control`;
        this.BaseElement.classList.add("iconbutton");
        this.BaseElement.classList.add(CLASS_CONTROL);
        this.BaseElement.classList.add(CLASS_UNSELECTABLE);

        this.helpElement = document.createElement("div") as HTMLDivElement;
        this.helpElement.innerText = title;
        this.helpElement.classList.add("buttonhelp");
        this.helpElement.id = `${id}-help`;

        switch (position) {
            case ButtonPosition.TopLeft:
                const verticalTopPosition = CustomButtonControl.TopLeftVerticalPosition;
                this.BaseElement.style.top = `${verticalTopPosition}em`;
                this.BaseElement.style.left = `${CustomButtonControl.HorizontalPosition}em`;
                this.helpElement.style.left = `${CustomButtonControl.MarginBetweenButtons}em`;
                this.helpElement.classList.add("buttonhelp-left");
                break;
            case ButtonPosition.BottomRight:
                const verticalBottomPosition = CustomButtonControl.BottomRightVerticalPosition;
                this.BaseElement.style.bottom = `${verticalBottomPosition}em`;
                this.BaseElement.style.right = `${CustomButtonControl.HorizontalPosition}em`;
                this.helpElement.style.right = `${CustomButtonControl.MarginBetweenButtons}em`;
                this.helpElement.classList.add("buttonhelp-right");
                break;
        }

        this.buttonElement = document.createElement("button") as HTMLButtonElement;
        this.buttonElement.id = `${id}-button`;
        this.buttonElement.title = title;

        const _this = this;

        this.buttonElement.onmouseenter = () => _this.DisplayHelp(true, false);
        this.buttonElement.onmouseup = () => _this.DisplayHelp(false, false);
        this.buttonElement.onmouseleave = () => _this.DisplayHelp(false, false);

        this.buttonElement.addEventListener("click", () => {
            clickFunction();
        }, false);

        SVGLoader.materialIconsLoader.Execute((svg) => this.SetSvgContentAsync(svg, id));

        this.BaseElement.appendChild(this.buttonElement);
        this.BaseElement.appendChild(this.helpElement);

        this.iconColours = {};
        this.iconColours[ButtonState.Normal] = "#000";
        this.iconColours[ButtonState.Active] = "#090";
        this.iconColours[ButtonState.Pending] = "#990";
        this.iconColours[ButtonState.Error] = "#900";

        this.DisplayHelp(false, true);
    }

    public Disable() {
        this.baseElement.classList.add(CSSConstants.disabled);
    }

    public Enable() {
        this.baseElement.classList.remove(CSSConstants.disabled);
    }

    public SetState(state: ButtonState) {
        const states = Object.keys(ButtonState).map((v) => ButtonState[v]) as ButtonState[];

        states.forEach((s) => {
            if (s === state) {
                this.BaseElement.classList.add(s);
                if (this.svgIconElement) {
                    this.svgIconElement.style.fill = this.iconColours[state];
                }
            } else {
                this.BaseElement.classList.remove(s);
            }
        });
    }

    public DisplayHelp(display: boolean, forced: boolean) {
        if (!forced && this.helpForced) {
            return;
        }

        if (forced) {
            this.helpForced = display;
        }

        if (display && !this.baseElement.classList.contains(CSSConstants.disabled)) {
            this.helpElement.classList.remove(CSSConstants.hidden);
        } else {
            this.helpElement.classList.add(CSSConstants.hidden);
        }
    }

    private SetSvgContentAsync(svgContentDocument: SVGElement, id: string) {
        svgContentDocument.id = `${id}-svg`;

        this.svgIconElement = SVGLoader.IsolateChild(svgContentDocument, id);

        this.BaseElement.appendChild(svgContentDocument);
    }

}

enum ButtonPosition {
    Unset,
    TopLeft,
    BottomRight,
}

enum ButtonState {
    Normal = "iconbutton-normal",
    Active = "iconbutton-active",
    Pending = "iconbutton-pending",
    Error = "iconbutton-error",
}
