export default class Poster {

    public get Owners() {
        return this.owners;
    }

    public set Owners(owners: Set<string>) {
        if (owners) {
            this.owners.clear();
            const ownersArray = Array.from(owners);
            ownersArray.forEach((owner) => this.owners.add(owner));
        }
    }
    public static readonly FeaturePosterProperty = "poster";
    public static readonly FeatureSyncProperty = "sync";

    public State: number;
    public Position: number;
    public Comment: string;

    private owners: Set<string>;

    constructor() {
        this.State = 0;
        this.Position = 0;
        this.Comment = "";
        this.owners = new Set<string>();
    }

    public PopupSet(setting: PopupPosterSetting, value: string) {
        switch (setting) {
            case PopupPosterSetting.State:
                this.State = +value;
                break;
            case PopupPosterSetting.Position:
                this.Position = +value;
                break;
            case PopupPosterSetting.Owner:
                this.Owners = ownerStringToSet(value);
                break;
            case PopupPosterSetting.Comment:
                this.Comment = value;
                break;
        }
    }
}

export enum PosterSyncState {
    Add,
    Modify,
    Delete,
    Synced,
}

export enum PopupPosterSetting {
    State = "state",
    Position = "position",
    Owner = "owner",
    Comment = "comment",
    SelectionSize = "selectionsize",
    StateDescription = "statedescription",
}

export function ownerStringToSet(ownerString: string): Set<string> {
    const ownerArray = ownerString.split(",");
    const trimmedOwnerArray = [];
    ownerArray.forEach((owner) => {
        const trimmedOwner = owner.trim();
        if (trimmedOwner.length > 0) {
            trimmedOwnerArray.push(trimmedOwner);
        }
    });
    trimmedOwnerArray.sort();
    return new Set<string>(trimmedOwnerArray);
}

export function ownerSetToString(value: Set<string>, pretty: boolean): string {
    const ownerArray = Array.from(value);
    ownerArray.sort();
    const separator = pretty ? ", " : ",";
    return ownerArray.join(separator);
}
