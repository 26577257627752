import SVGLoader from "../svgloader";

export default class PopupPart {

    public get PartDiv() {
        return this.divElement;
    }

    protected labelElements: { [label: string]: HTMLLabelElement };
    protected transparentForegroundElement: HTMLDivElement;
    private name: string;
    private divElement: HTMLDivElement;
    private tableElement: HTMLTableElement;
    private buttonsElement: HTMLDivElement;

    private buttonSets: { [setId: string]: HTMLDivElement };

    constructor(name: string) {
        this.name = name;
        this.labelElements = {};
        this.buttonSets = {};
        this.createBasicStructure();
    }

    protected AddRow(childLeft: HTMLElement, childRight?: HTMLElement) {
        const tr = document.createElement("tr");
        this.tableElement.appendChild(tr);

        const leftTd = document.createElement("td") as HTMLTableDataCellElement;

        tr.appendChild(leftTd);
        leftTd.appendChild(childLeft);

        if (childRight) {
            leftTd.classList.add("popup-leftcolumn");
            const rightTd = document.createElement("td");
            rightTd.classList.add("popup-rightcolumn");
            tr.appendChild(rightTd);
            rightTd.appendChild(childRight);
        } else {
            leftTd.colSpan = 2;
            leftTd.classList.add("popup-singlecolumn");
        }
    }

    protected AddButtons(buttonSetId: string, buttons: PopupButton[], additionalContent?: HTMLElement) {
        const id = `popup-${this.name}-buttons-${buttonSetId}`;

        const divElement = document.createElement("div") as HTMLDivElement;
        divElement.id = id;

        if (additionalContent) {
            divElement.appendChild(additionalContent);
        }

        buttons.forEach((button) => {
            divElement.appendChild(button.ButtonElement);
        });

        this.buttonsElement.appendChild(divElement);

        this.buttonSets[buttonSetId] = divElement;
    }

    protected ActivateButtonSet(buttonSetId: string) {
        for (const id in this.buttonSets) {
            if (this.buttonSets.hasOwnProperty(id)) {
                this.buttonSets[id].style.display = id === buttonSetId ? "block" : "none";
            }
        }
    }

    protected AddTitleRow(label: string, contentId: string, content: HTMLElement, singleRow?: boolean) {
        const id = `popup-${this.name}-${contentId}`;

        const labelElement = document.createElement("label") as HTMLLabelElement;
        labelElement.innerText = label;
        labelElement.htmlFor = id;

        this.labelElements[contentId] = labelElement;

        const divElement = document.createElement("div") as HTMLDivElement;
        divElement.id = id;
        divElement.appendChild(content);

        if (singleRow) {
            const parentElement = document.createElement("div");
            parentElement.appendChild(labelElement);
            parentElement.appendChild(divElement);
            this.AddRow(parentElement);
        } else {
            this.AddRow(labelElement, divElement);
        }
    }

    protected activateTransparentForeground(activate: boolean) {
        this.transparentForegroundElement.style.pointerEvents = activate ? "unset" : "none";
    }

    private createBasicStructure() {
        this.divElement = document.createElement("div");
        this.divElement.id = `popup-${this.name}`;

        this.transparentForegroundElement = document.createElement("div");
        this.transparentForegroundElement.style.position = "fixed";
        this.transparentForegroundElement.style.top = "0";
        this.transparentForegroundElement.style.bottom = "0";
        this.transparentForegroundElement.style.left = "0";
        this.transparentForegroundElement.style.right = "0";
        this.transparentForegroundElement.style.zIndex = "1";
        this.divElement.appendChild(this.transparentForegroundElement);

        this.activateTransparentForeground(false);

        this.tableElement = document.createElement("table") as HTMLTableElement;
        this.tableElement.classList.add("popup-table");
        this.divElement.appendChild(this.tableElement);

        this.buttonsElement = document.createElement("div") as HTMLDivElement;
        this.buttonsElement.classList.add("popup-buttons");
        this.divElement.appendChild(this.buttonsElement);
    }
}

export class PopupButton {
    private onClick: () => void;
    private title: string;
    private iconId: string;
    private classes: string[];

    constructor(clickFunction: () => void, title: string, iconId?: string, classes?: string[]) {
        this.onClick = clickFunction;
        this.title = title;
        this.iconId = iconId;
        this.classes = classes;
    }

    public get ButtonElement() {
        const buttonElement = document.createElement("button") as HTMLButtonElement;

        if (this.classes) {
            this.classes.forEach((c) => {
                buttonElement.classList.add(c);
            });
        }

        buttonElement.onclick = () => this.onClick();

        if (!this.iconId) {
            buttonElement.innerText = this.title;
        } else {
            SVGLoader.materialIconsLoader.Execute((svg) => {
                svg.childNodes.forEach((child) => {
                    if (child.nodeType === child.ELEMENT_NODE) {
                        const childElement = child as HTMLElement;

                        if (childElement.hasAttribute("id")) {
                            const childId = childElement.getAttribute("id");
                            if (childId === this.iconId) {
                                childElement.removeAttribute("id");
                            } else {
                                svg.removeChild(childElement);
                            }
                        }
                    }
                });

                svg.classList.add("inline-icon");
                buttonElement.appendChild(svg);
                buttonElement.appendChild(document.createTextNode(this.title));
            });
        }

        return buttonElement;
    }
}
