export default class Localization {

    public static readonly Version = "190313";

    public static readonly displayStrings = {
        title: "Linkscloud Plakate",

        popupPoster: {
            state: "Zustand",
            position: "Position",
            owner: "Plakatierende",
            ownerplaceholder: "Namen durch Komma getrennt",
            comment: "Kommentar",
            commentplaceholder: "Gibt es etwas Wichtiges zu diesem Plakat zu sagen?",
            abort: "Abbrechen",
            save: "Speichern",
            delete: "Löschen",
        },
        popupFilter: {
            state: "Zustand",
            owner: "Plakatierende",
            ownermodedescription: "Anzeigen als ",
            ownermodeseparated: "Personen",
            ownermodegrouped: "Teams",
            noowner: "Ohne Angabe",
            all: "Alle zeigen",
            none: "Alle verstecken",
            close: "Schließen",
        },
        popupMapCenter: {
            title: "Kartenmittelpunkt festlegen",
            centerdescription: "Bitte die Karte so verschieben, wie sie nach dem Einloggen stehen soll.",
            centervalue: "Koordinaten: ",
            save: "Speichern",
            abort: "Abbrechen",
        },
        menu: {
            map: "Karte",
            administation: "Administration",
            fullscreen: "Vollbild",
            help: "Anleitung",
            logout: "Ausloggen",
            about: "Infos / Feedback",
            privacy: "Datenschutz",
            legal: "Rechtliches"
        },
        help: {
            user: {
                title: "Plakatieren",
                description: "Wie das Plakatieren mit Linkscloud Plakate funktioniert"
            },
            admin: {
                title: "Administration",
                description: "Das Handbuch für Administratoren wie dich. Wie mache ich den Account startklar?"
            },
            superuser: {
                title: "Benutzerverwaltung",
                description: "Wie man weitere Benutzer hinzufügt und verwaltet"
            },
        },
        buttons: {
            "Menu": "Menü",
            "Zoom In": "Reinzoomen",
            "Zoom Out": "Rauszoomen",
            "Geolocation": "Mein Standort",
            "Add Poster": "Plakat hinzufügen",
            "Filter Poster": "Plakate filtern",
            "Help": "Hilfe",
            "Rotate": "Rotation zurücksetzen",
        },
        info: {
            "Add Poster": "Wähle die Stelle, an der du das Plakat hinzufügen möchtest."
        },
        login: {
            username: "Benutzername",
            password: "Passwort",
            submit: "Anmelden",
            messageLoginFailed: "Anmeldung fehlgeschlagen"
        },
        state: {
            0: ["hängt", "#d60027"],
            1: ["braucht Zuwendung", "#ff9900"],
            2: ["abgehängt", "#9b9b9b"],
            3: ["hängt wieder", "#e65050"],
            s: ["(ausgewählt)", "#0030d6"],
            h: ["(home)", "#aa0000"]
        },
        position: {
            0: "Laterne",
            1: "Baum",
            2: "Verkehrsinsel",
            3: "andere"
        },
        httpstatus: {
            default: "Unerwarteter HTTP-Statuscode ",
            200: "Alles okay",
            500: "Allgemeiner Serverfehler",
            503: "Der Server ist vorübergehend nicht erreichbar."
        },
        backendError: "Keine Verbindung zum Server möglich.",
        geolocationError: "Der Ortungsdienst kann nicht aufgerufen werden.",
        posterXMLError: "Unerwarteter Fehler beim Verarbeiten der Plakatliste: ",
        admin: {
            title: "Administation",
            titleSupervise: "Adminstration für {0}",
            username: "Benutzername",
            email: {
                title: "E-Mail-Adresse",
                header: "E-Mail-Adresse ändern",
                new: "Neue E-Mail-Adresse für {0}",
                help: "Die Angabe einer E-Mail-Adresse ist freiwillig und dient nur dazu, " +
                    "den Administrator des Accounts erreichen zu können, wenn es mal nötig sein sollte.",
                modifyButton: "E-Mail ändern",
                messageSuccess: "E-Mail-Adresse erfolgreich geändert"
            },
            password: {
                title: "Passwort",
                header: "Passwort ändern",
                new: "Neues Passwort für {0}",
                repeat: "Neues Passwort wiederholen",
                description: "Ändere das Passwort für Plakatierende",
                descriptionUnset: "⚠ Das Passwort für Plakatierende ist noch nicht gesetzt!",
                modifyButton: "Passwort ändern",
                messageSuccess: "Passwort erfolgreich geändert"
            },
            adminPassword: {
                title: "Administatorpasswort",
                header: "Administratorpasswort ändern",
                new: "Neues Administratorpasswort für {0}",
                repeat: "Neues Administatorpasswort wiederholen",
                description: "Ändere das Passwort für Administratoren",
            },
            currentAdminPassword: "Aktuelles Administratorpasswort von {0}",
            mapCenter: {
                title: "Startpunkt der Karte",
                description: "Lege fest, an welcher Stelle die Karte nach dem Login stehen soll",
                messageSuccess: "Startpunkt erfolgreich geändert"
            },
            addUser: {
                title: "Benutzer hinzufügen",
                description: "Lade weitere Benutzer ein",
                username: "Neuer Benutzername (bspw. Name des Kreisverbands)",
                email: "E-Mail-Adresse des neuen Administrators",
                isSuperuser: "Darf weitere Benutzer hinzufügen ⚠",
                adduserButton: "Benutzer hinzufügen",
                welcomeMessageLabel: "Diesen Einladungstext bitte kopieren und auf sicherem Wege an den neuen Benutzer versenden:",
                invitation: {
                    intro: "Hallo!\r\nDu bist herzlich eingeladen, Linkscloud Plakate zur Verwaltung eurer Wahlkampfplakate zu nutzen.\r\n" +
                        "Es wurden folgende Zugangsdaten für dich angelegt:\r\n",
                    url: "\r\nAdresse: ",
                    username: "\r\nBenutzername: ",
                    password: "\r\nPasswort: ",
                    outro: "\r\n\r\nBitte logge dich bald ein und ändere das Administratorpasswort.\r\nViel Vergnügen!"
                },
                copyButton: "Kopieren",
                messageSuccess: "Benutzer erfolgreich hinzugefügt",
                messageCopy: "Einladungstext in die Zwischenablage kopiert"
            },
            superwiseUser: {
                title: "Benutzer verwalten",
                description: "Verwalte die Benutzer, die du eingeladen hast",
                poster: "{0} Plakate",
                subuser: "Verwaltet {0} weitere Benutzer",
            },
            delete: {
                title: "Account löschen",
                description: "Lösche diesen Account mit allen Plakaten unwiderruflich",
                header: "Account {0} löschen",
                deleteUserButton: "Den Account {0} unwiderruflich löschen",
                messageSuccess: "Account erfolgreich gelöscht"
            },
            error: {
                1: "Fehlende Administratorrechte",
                2: "Aktuelles Administratorpasswort stimmt nicht",
                3: "Das Passwort für Plakatierende darf nicht mit dem für Administatoren übereinstimmen",
                4: "Der Benutzername kann nicht verwendet werden",
                5: "Der Benutzer darf nicht gelöscht werden",
                6: "Das Passwort befindet sich auf einer Liste kompromittierter Passwörter und darf nicht verwendet werden",
                passwordTooShort: "Das Passwort muss mindestens 8 Zeichen lang sein",
                passwordRepeatNotIdentical: "Passwort und Wiederholung stimmen nicht überein",
                usernameTooShort: "Der Benutzername muss mindestens 3 Zeichen lang sein",
                usernameTooLong: "Der Benutzername darf höchstens 50 Zeichen lang sein"
            },
        }
    };

    public static readonly MapCenter: [number, number] = [1493129, 6895780];

    constructor() {
        document.title = Localization.displayStrings.title;
        document.getElementById("version").innerText = Localization.Version;
    }
}
