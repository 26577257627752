import Localization from "../localize";
import SVGLoader from "../svgloader";

export default class PopupHelper {
    public static AddContentToStateButton(state: number, parentElement: HTMLElement) {
        PopupHelper.GenerateStateSvg(state, (svg) => {
            parentElement.appendChild(document.createTextNode(Localization.displayStrings.state[state][0]));
            svg.classList.add("inline-icon");
            parentElement.appendChild(svg);
        });
    }

    public static GenerateStateSvg(state: number, operation: (svg) => void) {
        SVGLoader.stateLoader.Execute((svg: SVGElement) => {
            svg.childNodes.forEach((child) => {
                if (child.nodeType === child.ELEMENT_NODE) {
                    const childElement = child as HTMLElement;

                    if (childElement.hasAttribute("id")) {
                        const childId = childElement.getAttribute("id");
                        if (childId === "backgroundcircle") {
                            childElement.style.fill = Localization.displayStrings.state[state][1];
                            childElement.removeAttribute("id");
                        } else if (childId === `state-${state}-icon`) {
                            childElement.removeAttribute("id");
                        } else {
                            svg.removeChild(childElement);
                        }
                    }
                }
            });

            svg.setAttribute("viewBox", "0 0 24 24");

            operation(svg);
        });
    }
}
