import Collection from "ol/Collection";
import Feature from "ol/Feature";
import AppComponents from "./components";
import Poster, { ownerSetToString, PosterSyncState } from "./poster";

export default class ApplicationState {
    public get hasOwnerGroups(): boolean {
        let anyComma = false;
        this.ownerGroups.forEach((ownerGroup) => {
            if (ownerGroup.includes(",")) {
                anyComma = true;
            }
        });
        return anyComma;
    }

    public get selectedFeatures(): Collection<Feature> {
        return this._selectedClusterFeatures;
    }

    public get selectedPosterFeatures(): Feature[] {
        return this._selectedClusterFeatures.getArray();
    }

    public owners = new Set<string>();
    public ownerGroups = new Set<string>();

    public defaultPosterState: number;
    public defaultPosterPosition: number;
    public defaultOwners: Set<string>;

    public posterFeatures = new Collection<Feature>();
    public posterSubmitQueue = new Collection<Feature>([], { unique: true });
    private _selectedClusterFeatures = new Collection<Feature>();


    private addMode: boolean;

    constructor() {
        this.defaultOwners = new Set<string>();
        this.defaultPosterState = 0;
        this.defaultPosterPosition = 0;
        this.addMode = false;

        this.registerEvents();
    }

    public toggleAddMode() {
        this.addMode = !this.addMode;

        if (this.addMode) {
            AppComponents.Events.AddMode.Start.trigger();

        } else {
            AppComponents.Events.AddMode.End.trigger();
        }
    }

    public DisableAddMode() {
        this.addMode = false;
        AppComponents.Events.AddMode.End.trigger();
    }

    public setSyncToAddOrModify(feature, toState: PosterSyncState) {
        const fromState = feature.get(Poster.FeatureSyncProperty) as PosterSyncState;

        switch (toState) {
            case PosterSyncState.Add:
                break;
            case PosterSyncState.Modify:
                switch (fromState) {
                    case PosterSyncState.Add:
                        break;
                    case PosterSyncState.Modify:
                        break;
                    case PosterSyncState.Delete:
                        // TODO Assertion
                        break;
                    case PosterSyncState.Synced:
                        feature.set(Poster.FeatureSyncProperty, PosterSyncState.Modify);

                        this.posterFeatures.remove(feature);
                        this.posterSubmitQueue.push(feature);
                        break;
                }
                break;
            case PosterSyncState.Delete:
                switch (fromState) {
                    case PosterSyncState.Add:
                        this.posterSubmitQueue.remove(feature);
                        break;
                    case PosterSyncState.Delete:
                        break;
                    default:
                        feature.set(Poster.FeatureSyncProperty, PosterSyncState.Delete);
                        this.posterFeatures.remove(feature);
                        this.posterSubmitQueue.push(feature);
                        break;
                }
                break;
        }
    }

    private registerEvents() {
        const _this = this;

        this._selectedClusterFeatures.on(["add"], () => {
            AppComponents.Events.Selection.Active.trigger();
        });

        this._selectedClusterFeatures.on(["remove"], () => {
            if (_this._selectedClusterFeatures.getLength() === 0) {
                AppComponents.Events.Selection.None.trigger();
            } else {
                AppComponents.Events.Selection.Active.trigger();
            }
        });

        this.posterFeatures.on(["add", "remove"], () => _this.updateOwners());
    }

    private updateOwners() {
        let changed = false;

        const owners = new Set<string>();
        const ownerGroups = new Set<string>();

        this.posterFeatures.forEach((posterFeature) => {
            const poster = posterFeature.get(Poster.FeaturePosterProperty) as Poster;
            const posterOwners = poster.Owners;

            if (posterOwners.size === 0) {
                owners.add("");
            } else {
                posterOwners.forEach((owner) => {
                    owners.add(owner);
                });
            }

            const ownerGroup = ownerSetToString(posterOwners, false);

            if (!this.ownerGroups.has(ownerGroup)) {
                changed = true;
            }

            ownerGroups.add(ownerGroup);
        });
        changed = changed || this.ownerGroups.size !== ownerGroups.size;

        if (changed) {
            this.owners = owners;
            this.ownerGroups = ownerGroups;

            AppComponents.Events.Owners.Change.trigger();
        }
    }
}

export function getPosterFeaturesCollection(featureCollection: Collection<Feature>): Feature[] {
    const posterFeatures = [];
    featureCollection.forEach((feature) => {
        const posterFeatures1 = getPosterFeatures(feature);
        if (posterFeatures1.length === 1) {
            posterFeatures.push(posterFeatures1[0]);
        }
    });
    return posterFeatures;
}

export function getPosterFeatures(feature: Feature | ol.render.Feature): Feature[] {
    const posterFeatures = [];
    const posterFeatures_ = feature.get("features") as Feature[];

    // This is a cluster feature
    if (posterFeatures_) {
        for (let i = 0; i < posterFeatures_.length; i++) {
            posterFeatures.push(posterFeatures_[i]);
        }
    } else {
        posterFeatures.push(feature);
    }

    return posterFeatures;
}
