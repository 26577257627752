export default class HTMLLoader {
    public static readonly contentLoader = new HTMLLoader(require("~/content.html"));

    private readonly contentElement = document.createElement("div");
    private readonly contentHTML: string;

    constructor(contentHTML: string) {
        this.contentHTML = contentHTML;
    }

    public getChild(id: string): HTMLElement {
        if (this.contentElement.innerHTML === "") {
            this.contentElement.innerHTML = this.contentHTML;
        }
        let result;
        this.contentElement.childNodes.forEach((child) => {
            if (child.nodeType === child.ELEMENT_NODE) {
                const childElement = child as HTMLElement;

                if (childElement.hasAttribute("id")) {
                    if (childElement.getAttribute("id") === id) {
                        result = childElement.cloneNode(true) as HTMLElement;
                        result.removeAttribute("id");
                    }
                }
            }
        });
        return result;
    }
}