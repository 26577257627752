import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import AppComponents from "./components";
import Localization from "./localize";
import Poster, { PosterSyncState } from "./poster";
import { GetUrlParam, UrlParams } from "./url";

export default class DemoMode {

    public static PrepareDemoMode(): void {
        AppComponents.Events.User.Login.trigger();

        const factorx = 20000;
        const factory = 20000;
        for (let i = 0; i < 500; i++) {

            const newFeature = new Feature();

            const geom = new Point([Localization.MapCenter[0] - factorx + Math.random() * factorx * 2,
            Localization.MapCenter[1] - factory + Math.random() * factory * 2]);

            newFeature.setGeometry(geom);

            const poster = new Poster();
            poster.State = Math.random() < 0.7 ? 0 : Math.floor(Math.random() * 4);
            poster.Position = Math.floor(Math.random() * 4);
            poster.Owners = new Set<string>([DemoMode.OwnerNames[Math.floor(DemoMode.OwnerNames.length * Math.random())]]);
            poster.Comment = "";

            newFeature.set(Poster.FeaturePosterProperty, poster);
            newFeature.set(Poster.FeatureSyncProperty, PosterSyncState.Synced);

            AppComponents.ApplicationState.posterFeatures.push(newFeature);
        }

        AppComponents.Backend;
        AppComponents.Map.centerViewTo(Localization.MapCenter);
        AppComponents.PosterSync.synchronize(false);
        AppComponents.Overlays.HideSpinner();
        AppComponents.Map.centerViewTo([Localization.MapCenter[0] + 1, Localization.MapCenter[1] + 1]);
    }
    private static readonly OwnerNames = ["Rosa", "Karl", "Friedrich"];
}

export function IsDemoMode(): boolean {
    return GetUrlParam(UrlParams.demo) !== undefined;
}
