import { Feature } from "openlayers";
import AppComponents from "./components";
import { default as ILiteEvent, default as LiteEvent } from "./liteevent";
import Poster, { ownerSetToString, PosterSyncState } from "./poster";

export default class Filters {

    public get OwnerGroupMode() {
        return this.ownerGroupMode;
    }

    public set OwnerGroupMode(mode: boolean) {
        this.ownerGroupMode = mode;
        AppComponents.Events.Filter.Changed.trigger();
        AppComponents.Events.Owners.Change.trigger();
    }

    private readonly states: Filter<number>;
    private readonly owners: Filter<string>;
    private readonly ownerGroups: Filter<string>;

    private ownerGroupMode = false;
    private filterConfigurationMode = false;

    constructor() {
        const beforeAddEvent = new LiteEvent<void>();
        const afterRemoveEvent = new LiteEvent<void>();

        const _this = this;

        beforeAddEvent.on(() => {
            if (_this.isEveryFilterEmpty()) {
                AppComponents.Events.Filter.On.trigger();
            }
        });

        afterRemoveEvent.on(() => {
            if (_this.isEveryFilterEmpty()) {
                AppComponents.Events.Filter.Off.trigger();
            }
        });

        this.states = new Filter<number>(beforeAddEvent.expose(), afterRemoveEvent.expose(), AppComponents.Events.Filter.Changed.expose());
        this.owners = new Filter<string>(beforeAddEvent.expose(), afterRemoveEvent.expose(), AppComponents.Events.Filter.Changed.expose());
        this.ownerGroups = new Filter<string>(beforeAddEvent.expose(), afterRemoveEvent.expose(), AppComponents.Events.Filter.Changed.expose());

        this.registerEvents();
    }

    public isOwnerDisplayed(owner: string, acceptEmptyFilter?: boolean): boolean {
        return (acceptEmptyFilter && this.owners.FilterEmpty) || this.owners.has(owner);
    }

    public isOwnerGroupDisplayed(ownerGroup: string, acceptEmptyFilter?: boolean): boolean {
        return (acceptEmptyFilter && this.ownerGroups.FilterEmpty) || this.ownerGroups.has(ownerGroup);
    }

    public isStateDisplayed(state: number, acceptEmptyFilter?: boolean): boolean {
        return (acceptEmptyFilter && this.states.FilterEmpty) || this.states.has(state);
    }

    public startFilterConfigurationMode() {
        AppComponents.ApplicationState.selectedFeatures.clear();
        this.filterConfigurationMode = true;
        AppComponents.Events.Filter.ConfigurationStart.trigger();
    }

    public endFilterConfigurationMode() {
        this.filterConfigurationMode = false;
        AppComponents.Events.Filter.ConfigurationEnd.trigger();
    }

    public toggleFilterConfigurationMode() {
        this.filterConfigurationMode = !this.filterConfigurationMode;
        if (this.filterConfigurationMode) {
            this.startFilterConfigurationMode();
        } else {
            this.endFilterConfigurationMode();
        }
    }

    public addStateToFilter(state: number) {
        this.states.add(state);
    }

    public removeStateFromFilter(state: number) {
        this.states.delete(state);
    }

    public addOwner(owner: string) {
        this.owners.add(owner);
    }

    public removeOwner(owner: string) {
        this.owners.delete(owner);
    }

    public addOwnerGroupToFilter(ownerGroup: string) {
        this.ownerGroups.add(ownerGroup);
    }

    public removeOwnerGroupFromFilter(ownerGroup: string) {
        this.ownerGroups.delete(ownerGroup);
    }

    public posterDisplayedFunction(feature: Feature | ol.render.Feature): boolean {
        const syncState = feature.get(Poster.FeatureSyncProperty) as PosterSyncState;

        // Always display freshly added poster
        if (syncState === PosterSyncState.Add || syncState === undefined) {
            return true;
        }

        const poster = feature.get(Poster.FeaturePosterProperty) as Poster;
        const state = +poster.State;
        if (!this.isStateDisplayed(state, true)) {
            return false;
        }

        if (this.ownerGroupMode) {
            return this.isOwnerGroupDisplayed(ownerSetToString(poster.Owners, true), true);
        } else {
            return this.isAnyOwnerDisplayed(poster.Owners);
        }
    }

    private isAnyOwnerDisplayed(owners: Set<string>): boolean {
        const _this = this;

        if (owners.size === 0) {
            owners.add("");
        }

        let displayed = false;
        owners.forEach((owner) => {
            if (_this.isOwnerDisplayed(owner, true)) {
                displayed = true;
            }
        });
        return displayed;
    }

    private registerEvents() {
        const _this = this;
        AppComponents.Events.Owners.Change.on(() => _this.updateOwners());
    }

    private isEveryFilterEmpty(): boolean {
        return this.states.FilterEmpty && (this.ownerGroupMode ? this.ownerGroups.FilterEmpty : this.owners.FilterEmpty);
    }

    private updateOwners() {
        const _this = this;
        this.owners.FilteredValues.forEach((owner) => {
            if (!AppComponents.ApplicationState.owners.has(owner)) {
                _this.removeOwner(owner);
            }
        });
        this.ownerGroups.FilteredValues.forEach((ownerGroup) => {
            if (!AppComponents.ApplicationState.ownerGroups.has(ownerGroup)) {
                _this.removeOwnerGroupFromFilter(ownerGroup);
            }
        });
    }
}

class Filter<T> {

    public get FilterEmpty() {
        return this.filteredValues.size === 0;
    }

    public get FilteredValues() {
        return Array.from(this.filteredValues);
    }
    private readonly filteredValues = new Set<T>();

    private readonly beforeAddEvent: ILiteEvent<void>;
    private readonly afterRemoveEvent: ILiteEvent<void>;
    private readonly changedEvent: ILiteEvent<void>;

    constructor(beforeAddEvent: ILiteEvent<void>, afterRemoveEvent: ILiteEvent<void>, changedEvent: ILiteEvent<void>) {
        this.beforeAddEvent = beforeAddEvent;
        this.afterRemoveEvent = afterRemoveEvent;
        this.changedEvent = changedEvent;
    }

    public has(value: T) {
        const values = this.filteredValues;
        const has = values.has(value);
        return has;
    }

    public clear() {
        this.filteredValues.clear();
    }

    public add(value: T) {
        this.beforeAddEvent.trigger();

        if (!this.filteredValues.has(value)) {
            this.filteredValues.add(value);
            this.changedEvent.trigger();
        }
    }

    public delete(value: T) {
        if (this.filteredValues.has(value)) {
            this.filteredValues.delete(value);
            this.changedEvent.trigger();
        }

        this.afterRemoveEvent.trigger();
    }
}
