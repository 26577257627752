export enum UrlParams {
    user = "user",
    demo = "demo"
}

export function CreateBaseUrlWithParam(params: Array<[UrlParams, string]>): string {
    let url = document.baseURI;
    const parameterBegin = url.indexOf("?");
    if (parameterBegin !== -1) {
        url = url.substr(0, parameterBegin);
    }

    if (params.length === 0) {
        return url;
    }

    let firstParam = true;
    params.forEach((param) => {
        url += firstParam ? "?" : "&";
        url += param[0];
        if (param[1] && param[1].length > 0) {
            url += "=" + encodeURIComponent(param[1]);
        }
        firstParam = false;
    });

    return url;
}

export function GetUrlParam(paramName: UrlParams): string {
    const url = new URL(window.location.href);
    if (!url.searchParams) {
        return undefined;
    }

    const demo = url.searchParams.get(paramName);
    return demo !== null ? demo : undefined;
}
